import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import LoadingOverlay from "react-loading-overlay";
import axios from "axios";
 
import $ from 'jquery';

import {useParams} from "react-router";

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
 
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
 
 
//const stripePromise = loadStripe('pk_test_vrAU1Vlga7ystZleoAsLBub8');
const stripePromise = loadStripe('pk_live_PtasgnsC5reW9HYygmXU4qlL');


const MyCheckoutForm = () => {

    let { clientSecret } = useParams();
    const [nameSurname, setNameSurname] = useState("");
    axios.get(
      'https://mobiapp.mahalome.com/user/getNameSurname/'+clientSecret, {
      })
      .then(res => { 
      //alert( res.data.data )
      setNameSurname(res.data.data);  
      })
      .catch(err => {
        // ....
      })

    const [message, setMessage] = useState("");

    const stripe = useStripe();
    const elements = useElements();
 
    const handleSubmitCard = async (event) => {
        // Block native form submission.
        event.preventDefault();
    
        if (!stripe || !elements) {
          // Stripe.js has not loaded yet. Make sure to disable
          // form submission until Stripe.js has loaded.
          return;
        }
    
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);
        
        /*
        // Use your card Element with other Stripe.js APIs
        const {error, paymentMethod} = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });

        if (error) {
          console.log('[error]', error);
        } else {
          console.log('[PaymentMethod]', paymentMethod);
        }

        */
    
        // const result = await stripe.confirmCardPayment('{CLIENT_SECRET}', {
        
        const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: elements.getElement(CardElement),
              billing_details: {
                name: nameSurname,
              },
            }

          });
          if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            setMessage(result.error.message)
            console.log(result.error.message);
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
              console.log( JSON.stringify(result.paymentIntent.status) )

              setMessage('Money transfer is successfull!')

              // Show a success message to your customer
              // There's a risk of the customer closing the window before callback
              // execution. Set up a webhook or plugin to listen for the
              // payment_intent.succeeded event that handles any business critical
              // post-payment actions.
            }
          }
      };

    return (
        <form onSubmit={handleSubmitCard}>
 
 <div className="cardBackground">
      <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
        
        <button type="submit" className="submit fullwidth">
          Pay Now
        </button>
        <br></br>
        <h5><b>Powered by Stripe</b></h5>
        <br></br>
 </div>

 <br></br><br></br>

        
        <h3>{message}</h3>
        
      </form>
    )
}



const StripeCreditCard = () => {
 
  localStorage.setItem("whereAmI", "Main");
  const [jwt, setJwt] = useState(localStorage.getItem("jwt"));
   
  useEffect(() => {
     
  }, []);

 
   

  return (

<div className="container">
    <div className="row background ">
        <div className="col-md-12">
            <div className="form-group login-form">
                <Elements stripe={stripePromise}>
                    <MyCheckoutForm />
                </Elements>
            </div>
        </div>
    </div>
</div>
          
  
  );
};

export default StripeCreditCard;

  