import React,{useEffect, useState} from "react";
import { Link, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';
import axios from 'axios';

import {useParams} from "react-router";

import Header from "./includes/header";
import Footer from "./includes/footer";

const SendMoney = () => {

    let { receiverId } = useParams();

    localStorage.setItem('whereAmI', "Main");
    const [jwt, setJwt] = useState(localStorage.getItem('jwt'));

    const [load, setLoad] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [result, setResult] = useState('');
    const [redirect, setRedirect] = useState(0);

    const [serviceId, setServiceId] = useState('');
    const [serviceAmount, setServiceAmount] = useState(0);
    const [buttonMoneyValue, setButtonMoneyValue] = useState('');
    const [sendingMoneyRedirect, setSendingMoneyRedirect] = useState('');
    
    const [authCheck, setAuthCheck] = useState('Authenticated');

    const [sendButton, setSendButton] = useState('');

    let varReceiverId = '';

    if (receiverId) {
        varReceiverId = receiverId;
    } else {
        varReceiverId = '';
    }

    const [targetSystemId, setTargetSystemId] = useState(varReceiverId);
    const [preDefinedAmount, setPreDefinedAmount] = useState();
    const [savedReceiver, setSavedReceiver] = useState('');
    const [freeAmount, setFreeAmount] = useState('');
    const [readyForSend, setReadyForSend] = useState('T');
    const [receiverList, setReceiverList] = useState([]);
    const [isActivated, setIsActivated] = useState('');
    const [userInfo, setUserInfo] = useState('');

    const [maxTransAmount, setMaxTransAmount] = useState(100);

    

    useEffect(() => {

        if (targetSystemId.length === 7) {
            getReceiverInfo();
        } else {
            setUserInfo('');
        }

        checkActivation();

        axios.get(
            'https://mobiapp.mahalome.com/user/auth/check-session', {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/x-www-form-urlencoded',
                'token': jwt,
                'pageName': 'SendMoney'
            }
            })
            .then(res => { 
            setAuthCheck(res.data.result);
            })
            .catch(err => {
              setAuthCheck('401');
            }) 


            axios.get(
                'https://mobiapp.mahalome.com/user/checkMaxTransAmount', {
                headers: {
                    'accept': 'application/json',
                    'content-type': 'application/x-www-form-urlencoded',
                    'token': jwt,
                    'pageName': 'SendMoney'
                }
                })
                .then(res => {  
                    console.log(res.data.resultDetail.maxTransAmount);
                    setMaxTransAmount(res.data.resultDetail.maxTransAmount);
                })
                .catch(err => {
                }) 

        getSavedList();
        setLoad(false);

    }, [targetSystemId]);
     
    const buttonSetter = event => {
        let myNumber = Number(event);
        if (myNumber > maxTransAmount) {
            setButtonMoneyValue(maxTransAmount);
        } else {
            setButtonMoneyValue(event);
        }
    }

    const checkActivation = () => {
        axios.get(
            'https://mobiapp.mahalome.com/user/checkActivation', {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/x-www-form-urlencoded',
                'token': jwt
            }
            })
            .then(res => { 
                
                if (res.data.data.UserStatus[0].status === "PASSIVE") {
                    setIsActivated(
                        <div class="alert alert-danger" role="alert">
                        Please activate your account.
                        </div>
                    );
                }
    
            })
            .catch(err => {
                setIsActivated('');
            }) 
    }

    const getReceiverInfo = event => {
        
        axios({
            method: "post",
            url: "https://mobiapp.mahalome.com/user/getReceiverInfo",
            params: {
                receiverToken: targetSystemId
            },
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              token: jwt
            }
          }).then(res => {

            localStorage.setItem("targetId", targetSystemId);
            localStorage.setItem("nameSurname", res.data.data.UserInfo[0].name + " " + res.data.data.UserInfo[0].surname);
            
            if (res.data.data.UserInfo[0].status!=="ACTIVE") {
                setSendButton(<button type="submit" disabled className="btn btn-primary fullwidth">Send Money (Passive User)</button>);
            } else {
                setSendButton(<button type="submit" className="btn btn-primary fullwidth">Send Money</button>);
            }

            

            setUserInfo(
            <div>
            {res.data.data.UserInfo[0].name} {res.data.data.UserInfo[0].surname}
            </div>);
        })
        .catch(err => {
            setUserInfo(<div>
            ID Error
            </div>);
        });
    }

    const getSavedList = event => {
        axios.get(
            'https://mobiapp.mahalome.com/user/saved-receiver', {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/x-www-form-urlencoded',
                'token': jwt
            }
        })
        .then(res => {
            setReceiverList(res.data.data.ReceiverList);
            console.log( JSON.stringify(res.data.data.ReceiverList) )
        })
        .catch(err => {
        })
    }

    const handleSubmit = event => {
        
        event.preventDefault();
        localStorage.setItem("sendingAmount",  buttonMoneyValue);
        setSendingMoneyRedirect("1");
    }
    
    if (sendingMoneyRedirect==="1") {
        return <Redirect to="/send-money-verification" />;
    }
    
	// Redirect if there is token
	if(authCheck!=="Authenticated" ){
        return <Redirect to="/login" />
    }

    return (
        
        <div>
            <Helmet>
            <meta charSet="utf-8" />
            <title>Send Money | Mahalome</title>
            <meta name="description" content="Send Money" />
            </Helmet>
            <LoadingOverlay active={load} spinner> 

            <Header />
            <div class="spacer-25"></div>

            <div className="col-md-12">
                <h1 className="mt-5">Send Money</h1>
            </div>

            {isActivated}
            {result}

                <div className="main-form">
                <div className="row background transbox">
                    <div className="col-md-12">

                    <form className="user" onSubmit={handleSubmit}>

                        {/* <div className="form-group login-form">
                        <label for="exampleInputEmail1 form-text-style"><span className="form-text-style">DEFINED RECEIVERS</span></label>
                        
                        <select className="form-control form-control-user" value={savedReceiver} onChange={(e) => { setSavedReceiver(e.target.value) }}>
                            <option value='xxx'>-- Please select from saved receivers --</option> 
                            {receiverList ? (
                            receiverList.map((receiverListValue, index) => (
                                <option value={receiverListValue.system_id}>{receiverListValue.name} {receiverListValue.surname}</option>
                                ))
                                ) : (
                                 ''
                                )}
                        </select> 

                        <small className="form-text text-muted"><i className="fas fa-sign-in-alt"></i>Please select from pre-defined amounts.</small>
                        </div> */}

                        <div className="form-group login-form">
                        <label for="exampleInputEmail1 form-text-style"><span className="form-text-style">TYPE RECEIVER ID {userInfo} </span></label>
                        <input type="text" value={targetSystemId} required onChange={(e) => { setTargetSystemId(e.target.value) }} className="form-control form-control-user" placeholder="Example: F84-MN2" maxLength="7" minLength="7" />
                        <small className="form-text text-muted"><i className="fas fa-sign-in-alt"></i>Type the userId. (It has to be in XXX-XXX format.)</small>
                        </div>

                        <div className="form-group login-form">
                            <hr />
                        </div>

                        <div className="form-group login-form">
                        <label for="exampleInputEmail1 form-text-style"><span className="form-text-style">HOW MUCH DO YOU WANT TO GIVE</span></label>
                        {/* <select className="form-control form-control-user" value={preDefinedAmount}     onChange={(e) => { setPreDefinedAmount(Number(e.target.value)) }}>
                            <option value={0}>-- Please select from pre-defined amounts --</option>
                            <option value={10}>10 $</option>
                            <option value={20}>20 $</option>
                            <option value={50}>50 $</option>
                        </select>  */}

                        <div className="row container form-group buttonlist">
                        
                        <div className="col-sm2"><button type="button" value="5" onClick={(e) => { setButtonMoneyValue(e.target.value) }} className="btn btn-primary middlewidth">$ 5</button></div>
                        <div className="col-sm2">&nbsp;</div>
                        <div className="col-sm2"><button type="button" value="10" onClick={(e) => { setButtonMoneyValue(e.target.value) }} className="btn btn-primary middlewidth">$ 10</button></div>
                        <div className="col-sm2">&nbsp;</div>
                        <div className="col-sm2"><button type="button" value="20" onClick={(e) => { setButtonMoneyValue(e.target.value) }} className="btn btn-primary middlewidth">$ 20</button></div>
                        <div className="col-sm2">&nbsp;</div>
                        </div>
 
                        <small className="form-text text-muted"><i className="fas fa-sign-in-alt"></i>Please select from pre-defined amounts.</small>
                        </div>

                        <div className="form-group login-form">
                        <label for="exampleInputEmail1 form-text-style"><span className="form-text-style">CUSTOM AMOUNT</span></label>
                        {/* <input type="number" required value={buttonMoneyValue} onChange={(e) => { setButtonMoneyValue(e.target.value) }} className="form-control form-control-user" placeholder="Example: 14" max={999} /> */}

        <input type="number" required value={buttonMoneyValue} onChange={(e) => { buttonSetter(e.target.value) }} className="form-control form-control-user" placeholder="Example: 14" max={999} />
              

                        <small className="form-text text-muted"><i className="fas fa-sign-in-alt"></i>Please type if you prefer to send another amount.</small>
                        </div>
                         
                        <div className="form-group login-form">

                        {sendButton}

                        </div>

                    </form>

                    <div className="spacer-25"></div>
                    <div className="spacer-25"></div>
                    </div>
                </div>
            </div>
 
            <div class="spacer-25"></div>

            </LoadingOverlay>
            <Footer />    
        </div>
 
    );
};

export default SendMoney;
