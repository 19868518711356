import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Route, Redirect } from 'react-router'

import App from './App';


ReactDOM.render(
  <BrowserRouter>
  <Route exact path="/" render={() => (
    <Redirect to="/login"/>
  )}/>
    <App />
  </BrowserRouter>
  ,document.getElementById('root'),
);

 
