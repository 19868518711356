import React,{useEffect, useState} from "react";
import { Link, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';
import axios from 'axios';

import {useParams} from "react-router";

import Header from "./includes/header";
import Footer from "./includes/footer";
 
const ResetPassword = () => {

    localStorage.setItem('whereAmI', "Main");
    const [token, setToken] = useState();
 
    const [load, setLoad] = useState(true);
    const [password, setPassword] = useState('');
    const [result, setResult] = useState(0);
    const [redirect, setRedirect] = useState(0);
    
    let { id } = useParams();
 
    useEffect(() => {

        setLoad(false);

        if (localStorage.getItem('token')) {
            setToken(localStorage.getItem('token'));
        } else {
            setToken("not-available");
        }

        // Yeri burası mı ? 
        if(token === "not-available"){
            return <Redirect to="/" />
        }

	}, []);
 
    const handleSubmit = event => {

        event.preventDefault();

        axios.post('https://mobiapp.mahalome.com/user/resetPassword',{
            password: password,
            token: id
        })
        .then(res => {    
            setResult(1);
        })
        .catch(err => {
            
            console.log(err.message);
            setResult(
                <div class="alert alert-danger" role="alert">
                    Temporary error occured, please try again later.
                </div>
            );
            
        })
       
    }
    
if (result===0) {

    return (
        
        <div>
            <Helmet>
            <meta charSet="utf-8" />
            <title>Reset Password | Mahalome</title>
            <meta name="description" content="Forgot Password" />
            </Helmet>
            <LoadingOverlay active={load} spinner> 
            <Header />
            <div class="spacer-25"></div>
            <div className="col-md-12">
                <h1 className="mt-5">Reset Password</h1>
            </div>
                <div className="main-form">
                <div className="row background transbox">
                    <div className="col-md-12">
                    <form className="user" onSubmit={handleSubmit}>
                        <div className="form-group login-form">
                        <label for="exampleInputEmail1 form-text-style"><span className="form-text-style">New Password</span></label>
                        <input type="password" value={password} required onChange={(e) => { setPassword(e.target.value) }} className="form-control form-control-user" placeholder="New Password" />
                        <small className="form-text text-muted">
                            <i className="fas fa-sign-in-alt"></i>Please reset your password.</small>
                        </div> 
                        <div className="form-group login-form">
                        <button type="submit" className="btn btn-primary fullwidth">Submit</button>
                        </div> 
                    </form>
                    <div className="spacer-25"></div>
                    <div className="spacer-25"></div>
                    </div>
                </div>
            </div>
            <div class="spacer-25"></div>
            </LoadingOverlay>
            <Footer />    
        </div>
 
    );
} else {
return (
        
        <div>
            <Helmet>
            <meta charSet="utf-8" />
            <title>Reset Password | Mahalome</title>
            <meta name="description" content="Forgot Password" />
            </Helmet>
            <LoadingOverlay active={load} spinner> 
            <Header />
            <div class="spacer-25"></div>
            <div className="col-md-12">
                <h1 className="mt-5">Reset Password</h1>
            </div>
                <div className="main-form">
                <div className="row background transbox">
                    <div className="col-md-12">
                    <div className="spacer-25"></div>
                    <div className="spacer-25"></div>
                    
                        <div className="form-group login-form text-center">
                        <label for="exampleInputEmail1 form-text-style"><span className="form-text-style">Password has been reset.</span></label>
                        </div> 

                        <br /><br />

                        <div className="form-group login-form text-center">
                        <label for="exampleInputEmail1 form-text-style">
                            <span className="form-text-style">You can go to <a href="/login">login</a> </span></label>
                        </div> 

                    <div className="spacer-25"></div>
                    <div className="spacer-25"></div>
                    <div className="spacer-25"></div>
                    </div>
                </div>
            </div>
            <div class="spacer-25"></div>
            </LoadingOverlay>
            <Footer />    
        </div>
 
    );
} 
    
};

export default ResetPassword;
