import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import LoadingOverlay from "react-loading-overlay";
import axios from "axios";

import Header from "./includes/header";
import Footer from "../auth/includes/footer";

const Dashboard = () => {
  localStorage.setItem("whereAmI", "Main");
  const [jwt, setJwt] = useState(localStorage.getItem("jwt"));

  const [load, setLoad] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [result, setResult] = useState("");
  const [redirect, setRedirect] = useState(0);

  const [authCheck, setAuthCheck] = useState('Authenticated');

  const [isActivated, setIsActivated] = useState('');

  useEffect(() => {
    setLoad(false);

    checkActivation();

    axios.get(
      'https://mobiapp.mahalome.com/user/auth/check-session', {
      headers: {
          'accept': 'application/json',
          'content-type': 'application/x-www-form-urlencoded',
          'token': jwt,
          'pageName': 'Dashboard'
      }
      })
      .then(res => { 
      setAuthCheck(res.data.result);
      })
      .catch(err => {
        setAuthCheck('401');
      }) 

  }, []);
 
  const checkActivation = () => {
    axios.get(
        'https://mobiapp.mahalome.com/user/checkActivation', {
        headers: {
            'accept': 'application/json',
            'content-type': 'application/x-www-form-urlencoded',
            'token': jwt
        }
        })
        .then(res => { 
         // console.log("***********!!!!"+ JSON.stringify(res.data.data.UserStatus[0].status) );

            if (res.data.data.UserStatus[0].status === "PASSIVE") {
                setIsActivated(
                    <div class="alert alert-danger" role="alert">
                    Please activate your account.
                    </div>
                );
            }

        })
        .catch(err => {
            setIsActivated('');
        }) 
}

  const handleSubmit = event => {
    event.preventDefault();

    // set value
    setEmail(email);
    setPassword(password);

    axios
      .post("https://mobiapp.mahalome.com/admin/login", {
        email: email,
        password: password
      })
      .then(res => {
        localStorage.setItem("token", res.data.jwt);
        console.log(localStorage.getItem("token"));

        setResult(
          <div class="alert alert-success" role="alert">
            {res.data.resultDetail}
          </div>
        );
        setRedirect(1);
      })
      .catch(err => {
        console.log(err.message);

        setResult(
          <div class="alert alert-danger" role="alert">
            Email or password is incorrect
          </div>
        );
      });
  };

	// Redirect if there is token
	if(authCheck!=="Authenticated" ){
    return <Redirect to="/login" />
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Main | Mahalome</title>
        <meta name="description" content="Send Money" />
      </Helmet>
      <LoadingOverlay active={load} spinner>
        <Header />

        <div class="spacer-90"></div>

        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <img src="./img/logo.png" height="60" />
            </div>
          </div>
        </div>

        <div class="spacer-25"></div>

        {isActivated}

        <div class="spacer-25"></div>
        <div className="container box">

          <div className="row text-center">
            <div className="col">
              <div className="bg-light">
                <a href="send-money">Send Money</a> &nbsp;
              </div>
            </div>

            <div className="col">
              <div className="bg-light">
                <a href="receive-money">Receive Money</a> &nbsp;
              </div>
            </div>
            </div>

          <div className="spacer-30"></div>
          
          <div className="row text-center">
            <div className="col">
              <div className="bg-light">
                <a href="balance">Wallet</a> &nbsp;
              </div>
            </div>

            <div className="col">
              <div className="bg-light">
                <a href="saved-list">Saved List</a> &nbsp;
              </div>
            </div>

          </div>
        </div>
        
        <div class="spacer-25"></div>
        <div class="spacer-25"></div>

      </LoadingOverlay>
      <Footer />
    </div>
  );
};

export default Dashboard;
