/* eslint-disable import/first */
import React,{useEffect, useState} from "react";
import { Link, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';
import axios from 'axios';

import Header from "./includes/header";
import Footer from "./includes/footer";
// var QRCode = require('qrcode.react');
import { QRCode } from 'react-qrcode-logo';

const ReceiveMoney = () => {

    localStorage.setItem('whereAmI', "Main");
    const [jwt, setJwt] = useState(localStorage.getItem('jwt'));
 
    const [load, setLoad] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
 
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [systemId, setSystemId] = useState('');

    const [result, setResult] = useState('');
    const [redirect, setRedirect] = useState(0);

    const [isActivated, setIsActivated] = useState('');

    const [authCheck, setAuthCheck] = useState('Authenticated');

    const myCanvas = document.createElement('canvas')


    const [locations, setLocations] = React.useState([])
 
    
    useEffect(() => {

        setLoad(false);

        checkActivation();

        axios.get(
            'https://mobiapp.mahalome.com/user/auth/check-session', {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/x-www-form-urlencoded',
                'token': jwt,
                'pageName': 'ReceiveMoney'
            }
            })
            .then(res => { 
            setAuthCheck(res.data.result);
            })
            .catch(err => {
              setAuthCheck('401');
            });

        axios.get(
            'https://mobiapp.mahalome.com/user/profile', {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/x-www-form-urlencoded',
                'token': jwt
                // 'content-type': 'application/x-www-form-urlencoded'
            }
        })
        .then(res => {
    
            localStorage.setItem('token', res.data.jwt);
            console.log(localStorage.getItem('token'));

            setSystemId(res.data.data.UserProfile.system_id);
            setName(res.data.data.UserProfile.name);
            setSurname(res.data.data.UserProfile.surname);
            setEmail(res.data.data.UserProfile.email);
 
            setResult(
                <div class="alert alert-success" role="alert">
                    {res.data.resultDetail}
                </div>
            );
            setRedirect(1);
    
        })
        .catch(err => {
            
            console.log(err.message);
            
            setResult(
                <div class="alert alert-danger" role="alert">
                    Email or password is incorrect
                </div>
            );
            
        })

	}, []);
    
const myPrint = () => {
    window.print();
}

    const checkActivation = () => {
        axios.get(
            'https://mobiapp.mahalome.com/user/checkActivation', {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/x-www-form-urlencoded',
                'token': jwt
            }
            })
            .then(res => { 
             // console.log("***********!!!!"+ JSON.stringify(res.data.data.UserStatus[0].status) );
    
                if (res.data.data.UserStatus[0].status === "PASSIVE") {
                    setIsActivated(
                        <div class="alert alert-danger" role="alert">
                        Please activate your account.
                        </div>
                    );
                }
    
            })
            .catch(err => {
                setIsActivated('');
            }) 
    }

    const handleSubmit = event => {

        event.preventDefault();
    
        // set value
        setEmail(email);
        setPassword(password);
    
        axios.post('https://mobiapp.mahalome.com/admin/login',{
            email: email,
            password: password
        })
        .then(res => {
    
            localStorage.setItem('token', res.data.jwt);
            console.log(localStorage.getItem('token'));
    
            setResult(
                <div class="alert alert-success" role="alert">
                    {res.data.resultDetail}
                </div>
            );
            setRedirect(1);
    
        })
        .catch(err => {
            console.log(err.message);
            setResult(
                <div class="alert alert-danger" role="alert">
                    Email or password is incorrect
                </div>
            );
        })
    }
    
  // Redirect if there is token
  if(authCheck!=="Authenticated" ){
    return <Redirect to="/login" />
  }

  

    return (
        
        <div>
            <Helmet>
            <meta charSet="utf-8" />
            <title>Receive Money | Mahalome</title>
            <meta name="description" content="Receive Money" />
            </Helmet>
            <LoadingOverlay active={load} spinner> 

            <Header />

            <div class="spacer-25"></div>
 
            <div className="col-md-12">
                <h1 className="mt-5">Receive Money</h1>
            </div>
            
            {isActivated}

                <div className="main-form">
                <div className="row background transbox">
                    <div className="col-md-12">
                    <form className="user" onSubmit={handleSubmit}>
                        
                        <div className="form-group login-form"> 
                        <table width="100%" style={{'text-align': 'center'}}>
                        
                            <tr>
                                <td><b>QR CODE</b></td>
                            </tr>
                            <tr>
                                <td>
                                <QRCode value = {`https://www.mahalome.com/?MahaloMeID=${systemId}`}
                                        bgColor="#FFF" 
                                        size={250}
                                        logoImage="/img/QrLogoCenter.png"
                                     />
                                </td>
                            </tr>
                            <div class="spacer-25"></div>
                            <tr>
                                <td><b>USER ID</b></td> 
                            </tr>
                            <tr>
                                <td>{systemId}</td>
                            </tr>

                            <div class="spacer-25"></div>

                            <tr>
                                <td><b>NAME SURNAME</b></td>
                            </tr>                 

                            <tr>
                                <td>{name} {surname}</td>
                            </tr>

                            <tr>
                                <td>&nbsp;</td>
                            </tr>

                            <tr><td><button className="btn btn-success" onClick={() => myPrint()}>Print the QR</button></td></tr>

                        </table>
                        </div>  

                    </form>
                    <div className="spacer-25"></div>
                    <div className="spacer-25"></div>
                    </div>
                </div>
            </div>
 
            <div class="spacer-25"></div>

            </LoadingOverlay>
            <Footer />    
        </div>
 
    );
};

export default ReceiveMoney;
