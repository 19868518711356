import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import LoadingOverlay from "react-loading-overlay";
import axios from "axios";

import Header from "./includes/header";
import Footer from "./includes/footer";

import $ from 'jquery';

import Iframe from 'react-iframe'

const BankAccountsStripeAccount = () => {
  localStorage.setItem("whereAmI", "Main");
  const [jwt, setJwt] = useState(localStorage.getItem("jwt"));

  const [load, setLoad] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [result, setResult] = useState("");
  const [redirect, setRedirect] = useState(0);

  const [bankList, setBankList] = useState([]);
  const [cardList, setCardList] = useState([]);

  const [newCardHolderName, setNewCardHolderName] = useState("");
  const [newCardNumber, setNewCardNumber] = useState("");
  const [newCvcNumber, setNewCvcNumber] = useState("");
  const [newExpireDate, setNewExpireDate] = useState("");

  const [newIban, setNewIban] = useState("");
  const [newBankName, setNewBankName] = useState("");

  const [creditCardIndexNumber, setCreditCardIndexNumber] = useState(0);
  const [bankAccountIndexNumber, setBankAccountIndexNumber] = useState(0);

  const [gosterGostermeBank, setGosterGostermeBank] = useState('show');
  const [gosterGostermeCard, setGosterGostermeCard] = useState('show');
  const [gosterGostermeMyModal1, setGosterGostermeMyModal1] = useState('show');
  const [gosterGostermeMyModal2, setGosterGostermeMyModal2] = useState('show');

  const [redirectToLoadMoney, setRedirectToLoadMoney] = useState(0);
  const [redirectToWithDraw, setRedirectToWithDraw] = useState(0);

  const [authCheck, setAuthCheck] = useState('Authenticated');
  const [isActivated, setIsActivated] = useState('');

  const [stateToken, setStateToken] = useState('');

  useEffect(() => {
  
 
      checkActivation();

      getStripeStateToken();
 
      setLoad(false);

      axios.get(
        'https://mobiapp.mahalome.com/user/auth/check-session', {
        headers: {
            'accept': 'application/json',
            'content-type': 'application/x-www-form-urlencoded',
            'token': jwt,
            'pageName': 'BankAccounts'
        }
        })
        .then(res => { 
        setAuthCheck(res.data.result);
        })
        .catch(err => {
          setAuthCheck('401');
        }) 

  }, []);


  const checkActivation = () => {
    axios.get(
        'https://mobiapp.mahalome.com/user/checkActivation', {
        headers: {
            'accept': 'application/json',
            'content-type': 'application/x-www-form-urlencoded',
            'token': jwt
        }
        })
        .then(res => { 
         // console.log("***********!!!!"+ JSON.stringify(res.data.data.UserStatus[0].status) );

            if (res.data.data.UserStatus[0].status === "PASSIVE") {
                setIsActivated(
                    <div class="alert alert-danger" role="alert">
                    Please activate your account.
                    </div>
                );
            }

        })
        .catch(err => {
          setIsActivated('');
        }) 
}

   

  
  const getStripeStateToken = event => {

      axios({
        method: "post",
        url: "https://mobiapp.mahalome.com/user/service/get-stripe-state-token",
        params: {
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          token: jwt
        }
      })
        .then(res => {
          console.log( JSON.stringify(res.data.resultDetail) );
          setStateToken(res.data.resultDetail);
        })
        .catch(err => {
          setResult(
            <div className="alert alert-danger" role="alert">
              Temporary Error Occured
            </div>
          );
        });
  }

 
	// Redirect if there is token
	if(authCheck!=="Authenticated" ){
    return <Redirect to="/login" />
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>BankAccounts | Mahalome</title>
        <meta name="description" content="Profile" />
      </Helmet>
      <LoadingOverlay active={load} spinner>
        <Header />
        
        {/* <div className="spacer-90"></div> */}
        <div className="spacer-25"></div>

        <div className="container text-center">
          <div className="row">
            <div className="col-md-12">
              <h1 className="mt-5">Stripe Account</h1>
              <div className="spacer-30"></div>
            </div>
          </div>
          
        </div>

        {isActivated}
        
        <div className="container">
          <div className="row background ">
          
          <div className="col-md-12">
          <div className="form-group login-form">

 

    <Iframe url={`https://connect.stripe.com/express/oauth/authorize?response_type=code&client_id=ca_EA3GzFOK3jMHAbqebXmkAfDLgpnHNPK4&state=${stateToken}&suggested_capabilities[]=transfers&scope=read_write#/`}
        width="600px"
        height="450px"
        id="myId"
        className="myClassname"
        display="initial"
        position="relative"/>

          {/* <iframe src={`https://connect.stripe.com/express/oauth/authorize?response_type=code&client_id=ca_EA3GzzP3l9Go4zdwb2vu0f5mQeT6FZkT&state=${stateToken}&suggested_capabilities[]=transfers&scope=read_write#/`} title="W3Schools Free Online Web Tutorials"></iframe>
           */}
 
 
          </div>
          </div>
 
        </div>
        </div>
 
      </LoadingOverlay>
      <Footer />
 
      
            <div className="modal-footer">
            </div>
          </div>
  
  );
};

export default BankAccountsStripeAccount;
