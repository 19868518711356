import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import LoadingOverlay from "react-loading-overlay";
import axios from "axios";

import Header from "./includes/header";
import Footer from "./includes/footer";

import { QRCode } from 'react-qrcode-logo';

const Login = () => {
  localStorage.setItem("whereAmI", "Main");
  const [jwt, setJwt] = useState(localStorage.getItem("jwt"));

  const [load, setLoad] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [result, setResult] = useState("");
  const [redirect, setRedirect] = useState(0);

  const [authCheck, setAuthCheck] = useState('401');

  useEffect(() => {
    setLoad(false);

    axios.get(
      'https://mobiapp.mahalome.com/user/auth/check-session', {
      headers: {
          'accept': 'application/json',
          'content-type': 'application/x-www-form-urlencoded',
          'token': jwt
      }
      })
      .then(res => { 
        setAuthCheck(res.data.result);
      })
      .catch(err => {
        setAuthCheck('401');
      }) 

  }, []);

  const handleSubmit = event => {
    event.preventDefault();

    axios({
      method: "post",
      url: "https://mobiapp.mahalome.com/user/login",
      params: {
        email: email,
        password: password
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(res => {
        // localStorage.setItem('token', res.data.data.token);
        console.log(res.data.result + res.data.data.jwt);

        if (res.data.result === "Success") {
          setJwt(res.data.data.jwt);
          localStorage.setItem("jwt", res.data.data.jwt);
          localStorage.setItem("name", res.data.data.name);
          localStorage.setItem("surname", res.data.data.surname);
          setAuthCheck("Authenticated");
        } else {
          setResult(
            <div class="alert alert-success" role="alert">
              Wrong email or password!
            </div>
          );
        }
        setRedirect(1);
      })

      .catch(err => {
        console.log(err.message);
        setResult(
          <div class="alert alert-danger" role="alert">
            Email or password is incorrect
          </div>
        );
      });

    /*
        axios.post('https://mobiapp.mahalome.com/user/login',{
            email: email,
            password: password
        })
        .then(res => {
            // localStorage.setItem('token', res.data.data.token);
            console.log(res.data.result + res.data.data.jwt);
            if (res.data.result==="Success") {
                setJwt(res.data.data.jwt);
                localStorage.setItem('jwt', res.data.data.jwt);
            } else {
                setResult(
                    <div class="alert alert-success" role="alert">
                        Wrong email or password!
                    </div>
                );
            }
            setRedirect(1);
        })
        .catch(err => {
            console.log(err.message);
            setResult(
                <div class="alert alert-danger" role="alert">
                    Email or password is incorrect
                </div>
            );
        })
        */
  };
  
  // Redirect if there is token
	if(authCheck==="Authenticated" ){
	  return <Redirect to="/dashboard" />
  }
   
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login Mahalome | Mahalome</title>
        <meta name="description" content="Login Mahalome System" />
      </Helmet>
      <LoadingOverlay active={load} spinner>
        <div class="spacer-90"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <img src="./img/logo.png" height="60" />
            </div>
          </div>
        </div>

        <div class="spacer-30"></div>

        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="main-form">
                <div className="row background transbox">
                  <div className="col-md-12 shadow">
                    <form className="user" onSubmit={handleSubmit}>

                    <div className="form-group login-form">{result}</div>
                    
                      <div className="form-group login-form">
                        <label for="exampleInputEmail1 form-text-style">
                          <span className="form-text-style">Email address</span>
                        </label>
                        <input
                          type="email"
                          value={email}
                          required
                          onChange={e => {
                            setEmail(e.target.value);
                          }}
                          className="form-control form-control-user"
                          placeholder="Email"
                        />
                      </div>
                      <div className="form-group login-form">
                        <label for="exampleInputPassword1">
                          <span className="form-text-style">Password</span>
                        </label>
                        <input
                          type="password"
                          value={password}
                          required
                          onChange={e => {
                            setPassword(e.target.value);
                          }}
                          className="form-control form-control-user"
                          placeholder="Password"
                        />
                      </div>
                      <div className="form-group login-form">
                        <button
                          type="submit"
                          className="btn btn-pink fullwidth"
                        >
                          Submit
                        </button>
                      </div>

                      <div className="container form-link">
                        <div className="row">
                          <div className="col">
                            <a href="/register">Register</a>
                          </div>

                          <div className="col text-right">
                            <a href="forgot-password">Forgot Password</a>
                          </div>
                        </div>
                      </div>
                    </form>

                    <div className="spacer-25"></div>

                    <div className="container form-link">
                        <div className="row">
                          <div className="col"> 
                            <a href="https://www.apple.com/ios/app-store/"><img src="./img/iosDownload.png" width="150" /></a>
                            <div className="spacer-25"></div>
                            <a href="https://play.google.com/"><img src="./img/androidDownload.png" width="150" /></a>
                          </div>
 
                          <div className="col text-right">
                          <QRCode value = {`https://mobiapp.mahalome.com/checkApplication`}
                                          bgColor="#FFF" 
                                          size={110}
                                          logoImage="/img/QrLogoCenter.png"
                                      /> 
                          </div>
                        </div>
                      </div>
 




                    <div className="spacer-25"></div>
                    <div className="spacer-25"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="spacer-25"></div>
      </LoadingOverlay>
      <Footer />
    </div>
  );
};

export default Login;