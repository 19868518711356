import React,{useEffect, useState} from "react";
import { Link, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';
import axios from 'axios';

import Header from "./includes/header";
import Footer from "./includes/footer";
 
const BalanceHistory = () => {

    localStorage.setItem('whereAmI', "Main");

    const [jwt, setJwt] = useState(localStorage.getItem('jwt'));

    const [load, setLoad] = useState(true);
    const [historyList, setHistoryList] = useState([]);
    const [timeInterval, setTimeInterval] = useState(7);

    const [isActivated, setIsActivated] = useState('');
  
    const [authCheck, setAuthCheck] = useState('Authenticated');

    useEffect(() => {
      
        console.log(jwt);
        checkActivation();
        setLoad(false);

        axios.get(
          'https://mobiapp.mahalome.com/user/auth/check-session', {
          headers: {
              'accept': 'application/json',
              'content-type': 'application/x-www-form-urlencoded',
              'token': jwt,
              'pageName': 'BalanceHistory'
          }
          })
          .then(res => { 
          setAuthCheck(res.data.result);
          })
          .catch(err => {
            setAuthCheck('401');
          }) 

        axios.get(
            'https://mobiapp.mahalome.com/user/balance/history/'+timeInterval, {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/x-www-form-urlencoded',
                'token': jwt
            }
        })
        .then(res => {
            setHistoryList(res.data.data.walletList); 
        })
        .catch(err => {
        })

	}, [timeInterval]);
  
    const handleSubmit = event => {

        event.preventDefault();
       
    }

    const checkActivation = () => {
      axios.get(
          'https://mobiapp.mahalome.com/user/checkActivation', {
          headers: {
              'accept': 'application/json',
              'content-type': 'application/x-www-form-urlencoded',
              'token': jwt
          }
          })
          .then(res => { 
           // console.log("***********!!!!"+ JSON.stringify(res.data.data.UserStatus[0].status) );
  
              if (res.data.data.UserStatus[0].status === "PASSIVE") {
                  setIsActivated(
                      <div class="alert alert-danger" role="alert">
                      Please activate your account.
                      </div>
                  );
              }
  
          })
          .catch(err => {
            setIsActivated('');
          }) 
  }

	// Redirect if there is token
	if(authCheck!=="Authenticated" ){
    return <Redirect to="/login" />
}

    return (
        
        <div>
            <Helmet>
            <meta charSet="utf-8" />
            <title>Send Money | Mahalome</title>
            <meta name="description" content="Send Money" />
            </Helmet>
            <LoadingOverlay active={load} spinner> 

            <Header />
            <div class="spacer-25"></div>

            <div className="col-md-12">
                <h1 className="mt-5"><a href="/balance"><i class="fa fa-arrow-left" aria-hidden="true"></i></a> Balance History</h1>
            </div>
            
            {isActivated}

        <div className="container">
        
        <div className="col-md-12">
        <select className="form-control form-control-user" value={timeInterval} onChange={(e) => { setTimeInterval(e.target.value) }}>
        <option value={1}>Last 1 day</option>
        <option value={2}>Last 2 days</option>
        <option value={3}>Last 3 days</option>
        <option value={7}>Last 7 days</option>
        <option value={30}>Last 30 days</option>
        <option value={90}>Last 90 days</option>
        <option value={999}>All</option>
        </select> 
        <div className="col-md-12">&nbsp;</div>
        </div>

        </div>
         <div className="container">
          <div className="row background ">
            <div className="col-md-12">
                <div className="form-group login-form">
                  <table class="table">
                    <thead class="thead-dark">
                      <tr>
                        {/* <th scope="col">#</th> */}
                        <th scope="col">OPERATION</th>
                        <th scope="col">AMOUNT</th>
                        <th scope="col">TO / FROM</th>
                        <th scope="col">DATE</th>
                      </tr>
                    </thead>
                    <tbody>
                      
                      {historyList ? (
                        historyList.map((historyListValue, index) => (
                          <tr>
                            <td>{historyListValue.OPERATION}</td>
                            <td>{historyListValue.AMOUNT}</td>
                            <td>{historyListValue.TARGET}</td>
                            <td>{historyListValue.MYDATE}</td> 
                          </tr>
                        ))
                      ) : (
                        <div></div>
                      )}


                    </tbody>
                        <tr>
                            <td colSpan="4">
                                <a href="/balance" className="btn btn-primary fullwidth">Back to Balance</a>
                            </td>
                        </tr>
                </table>
        </div>
        </div>
        </div>
        </div>








 
 
            <div class="spacer-25"></div>

            </LoadingOverlay>
            <Footer />    
        </div>
 
    );
};

export default BalanceHistory;
