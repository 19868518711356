import React,{useEffect, useState} from "react";
import { Link, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';
import axios from 'axios';

import Header from "./includes/header";
import Footer from "./includes/footer";
 
const LoadMoney = () => {

    localStorage.setItem('whereAmI', "Main");

    const [jwt, setJwt] = useState(localStorage.getItem('jwt'));

    const [load, setLoad] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [result, setResult] = useState('');
    const [redirect, setRedirect] = useState(0);
 
    const [balance, setBalance] = useState(0);
    const [bonus, setBonus] = useState(0);
    const [tips, setTips] = useState(0);
    const [transfer, setTransfer] = useState(0);
    const [isActivated, setIsActivated] = useState('');

    const [walletMoney, setWalletMoney] = useState(0);
    const [walletBonus, setWalletBonus] = useState(0);
    const [mustSpent, setMustSpent] = useState(0);

    const [buttonMoneyValue, setButtonMoneyValue] = useState('');
    const [bankList, setBankList] = useState([]);
    const [bankId, setBankId] = useState([]);
    
    const [authCheck, setAuthCheck] = useState('Authenticated');

    const [redirectCreditCard, setRedirectCreditCard] = useState("");

    useEffect(() => {

        setLoad(false);
       
        checkActivation();

        axios.get(
          'https://mobiapp.mahalome.com/user/auth/check-session', {
          headers: {
              'accept': 'application/json',
              'content-type': 'application/x-www-form-urlencoded',
              'token': jwt,
              'pageName': 'LoadMoney'
          }
          })
          .then(res => { 
          setAuthCheck(res.data.result);
          })
          .catch(err => {
            setAuthCheck('401');
          }) 

        getList();
        getBalance();

	}, []);
    

const getBalance = event => {


        axios.get(
            'https://mobiapp.mahalome.com/user/wallet-all', {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/x-www-form-urlencoded',
                'token': jwt
                // 'content-type': 'application/x-www-form-urlencoded'
            }
        })
        .then(res => {
        console.log(res.data.data.walletHistory[0]);
            setWalletMoney(res.data.data.walletHistory[0].wallet_money);
            setWalletBonus(res.data.data.walletHistory[0].wallet_bonus);
            setMustSpent(res.data.data.walletHistory[0].must_spent);
    
        })
        .catch(err => {

            setResult(
            );
            
        })

}

const checkActivation = () => {
  axios.get(
      'https://mobiapp.mahalome.com/user/checkActivation', {
      headers: {
          'accept': 'application/json',
          'content-type': 'application/x-www-form-urlencoded',
          'token': jwt
      }
      })
      .then(res => { 
       // console.log("***********!!!!"+ JSON.stringify(res.data.data.UserStatus[0].status) );

          if (res.data.data.UserStatus[0].status === "PASSIVE") {
              setIsActivated(
                  <div class="alert alert-danger" role="alert">
                  Please activate your account.
                  </div>
              );
          }

      })
      .catch(err => {
          setIsActivated('');
      }) 
}

    const handleSubmit = event => {

        event.preventDefault();

        axios({
            method: "post",
            url: "https://mobiapp.mahalome.com/user/wallet/transfer/check-bank-to-user",
            params: {
                amount:buttonMoneyValue
            },
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              token: jwt
            }
          })
            .then(res => {
              
                        // Kısıta takılmadı, işlem devam eder.
                        if (res.data.result==="Success") {

                              axios({
                                method: "post",
                                url: "https://mobiapp.mahalome.com/user/service/prepare-card-payment-for-account",
                                params: {
                                    amount:buttonMoneyValue
                                },
                                headers: {
                                  "Content-Type": "application/x-www-form-urlencoded",
                                  token: jwt
                                }
                              })
                                .then(res => {
                                  console.log ("######"+JSON.stringify(res.data.data.clientSecret) ); 
                                  setRedirectCreditCard("WebStripeCreditCard/"+res.data.data.clientSecret)
                                })
                                .catch(err => {
                                  // .....
                                });

                        }

              console.log ( JSON.stringify(res) );
              setResult(  
                <div className="alert alert-danger" role="alert">
                {res.data.resultDetail}
              </div>);

              getBalance();
            })
            .catch(err => {
              setResult(
                <div className="alert alert-danger" role="alert">
                  Temporary Error Occured
                </div>
              );
            });

        
    }


    const getList = event => {
        // Banks
        axios
        .get("https://mobiapp.mahalome.com/user/bank", {
          headers: {
            accept: "application/json",
            "content-type": "application/x-www-form-urlencoded",
            token: jwt
            // 'content-type': 'application/x-www-form-urlencoded'
          }
        })
        .then(res => {
          setBankList(res.data.data.Banks);
        })
        .catch(err => {
          setResult(
            <div className="alert alert-danger" role="alert">
              Temporary Error Occured
            </div>
          );
        });
    }

// Redirect if there is token
	if(authCheck!=="Authenticated" ){
    return <Redirect to="/login" />
  }
  
  if(redirectCreditCard!=="" ){
    return <Redirect to={redirectCreditCard} />
  }

  
    return (
        
        <div>
            <Helmet>
            <meta charSet="utf-8" />
            <title>Load Funds | Mahalome</title>
            <meta name="description" content="Load Funds" />
            </Helmet>
            <LoadingOverlay active={load} spinner> 

            <Header />
            <div class="spacer-25"></div>
 
            <div className="col-md-12">
            <h1 className="mt-5">
              <a href="/bank-accounts"><i class="fa fa-arrow-left" aria-hidden="true"></i></a> Load Funds</h1>
            </div>
 
            {isActivated}

                <div className="main-form">
                <div className="row background transbox">
                  {/* left spacer */}
                  <div className="col-md-2"></div>
                  <div className="col-md-8">

                    <form className="user" onSubmit={handleSubmit}>

                    <div className="form-group login-form text-center form-text-style">
                       Wallet Money!
                    </div>
                     
                    <div className="form-group login-form text-center">
                       <h2><i class="fa fa-usd" aria-hidden="true"></i> {walletMoney}</h2>
                    </div>

                    <div className="form-group login-form text-center form-text-style">
                       Wallet Bonus
                    </div>
                     
                    <div className="form-group login-form text-center">
                       <h2><i class="fa fa-usd" aria-hidden="true"></i> {walletBonus}</h2>
                    </div>

                    <div className="form-group login-form text-center form-text-style">
                       Must Spent
                    </div>
                     
                    <div className="form-group login-form text-center">
                       <h2><i class="fa fa-usd" aria-hidden="true"></i> {mustSpent} </h2>
                    </div>
 
                    <div className="spacer-25"></div>
 
                        <div className="form-group login-form text-center">
                        
                        
                        {/* <label for="exampleInputEmail1 form-text-style"><span className="form-text-style">DEFINED BANKS</span></label>
                        
                        <select className="form-control form-control-user" value={bankId} onChange={(e) => { setBankId(e.target.value) }}>
                            <option value='xxx'>-- Please select from saved receivers --</option> 
                            {bankList ? (
                            bankList.map((bankListValue, index) => (
                                <option value={bankListValue.id}>{bankListValue.name} </option>
                                ))
                                ) : (
                                 ''
                                )}
                        </select>  */}
                        <i className="fas fa-sign-in-alt"></i><span className="form-text-style">Please select from pre-defined amounts.</span>

                        </div> 


                    <div className="row form-group text-center">
                        <div className="col"><button type="button" value="20" onClick={(e) => { setButtonMoneyValue(e.target.value) }} className="btn btn-primary">20$</button></div>
                        <div className="col"><button type="button" value="30" onClick={(e) => { setButtonMoneyValue(e.target.value) }} className="btn btn-primary">30$</button></div>
                        <div className="col"><button type="button" value="50" onClick={(e) => { setButtonMoneyValue(e.target.value) }} className="btn btn-primary">50$</button></div>
                    </div>

                    <div className="form-group login-form text-center">
                        <label for="exampleInputEmail1 form-text-style"><span className="form-text-style">LOAD AMOUNT</span></label>
                        <input type="number" value={buttonMoneyValue} onChange={(e) => { setButtonMoneyValue(e.target.value) }} className="form-control form-control-user" />
                        <small className="form-text text-muted"><i className="fas fa-sign-in-alt"></i>Please type if you prefer to send another amount.</small>
                    </div>

                    <div className="form-group login-form">
                        <button type="submit" className="btn btn-primary fullwidth">LOAD</button>
                    </div>
                    <div className="form-group login-form">
                        <a href="/bank-accounts" className="btn btn-primary fullwidth">Back to Bank Accounts</a>
                    </div>

                    <div className="form-group login-form">
                    {result}
                    </div>

                    </form>
                    <div className="spacer-25"></div>
                    <div className="spacer-25"></div>
                    </div>
                    {/* center-8 ... */}
                    {/* right spacer */}
                    <div className="col-md-2"></div>

                </div>
            </div>
 
            <div class="spacer-25"></div>

            </LoadingOverlay>
            <Footer />    
        </div>
 
    );
};

export default LoadMoney;
