import React,{useEffect, useState} from "react";
import { Link, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';
import axios from 'axios';

import Header from "./includes/header";
import Footer from "./includes/footer";

import $ from 'jquery';

var imageExists = require('image-exists');

const SavedList = () => {

    localStorage.setItem('whereAmI', "Main");
    const [jwt, setJwt] = useState(localStorage.getItem('jwt'));
 
    const [load, setLoad] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [result, setResult] = useState('');
    const [redirect, setRedirect] = useState(0);

    const [receiverList, setReceiverList] = useState([]);
    const [userid, setUserid] = useState('');
    const [newSystemId, setNewSystemId] = useState('');
    const [gosterGostermeDelete, setGosterGostermeDelete] = useState('show');
    const [gosterGostermeAdd, setGosterGostermeAdd] = useState('show');
    const [receiverIndexId, setReceiverIndexId] = useState('');
    const [isActivatedData, setIsActivatedData] = useState('');
    const [isActivated, setIsActivated] = useState('');

    const [authCheck, setAuthCheck] = useState('Authenticated');

    useEffect(() => {

        if (localStorage.getItem('targetId')) {
          handleSubmit2();
        }

        checkActivation();

        axios.get(
          'https://mobiapp.mahalome.com/user/auth/check-session', {
          headers: {
              'accept': 'application/json',
              'content-type': 'application/x-www-form-urlencoded',
              'token': jwt,
              'pageName': 'SavedList'
          }
          })
          .then(res => { 
          setAuthCheck(res.data.result);
          })
          .catch(err => {
            setAuthCheck('401');
          }) 


        getList();
        setLoad(false);



	}, []);
 
   
    const checkActivation = () => {
      axios.get(
          'https://mobiapp.mahalome.com/user/checkActivation', {
          headers: {
              'accept': 'application/json',
              'content-type': 'application/x-www-form-urlencoded',
              'token': jwt
          }
          })
          .then(res => { 
           // console.log("***********!!!!"+ JSON.stringify(res.data.data.UserStatus[0].status) );
  
              if (res.data.data.UserStatus[0].status === "PASSIVE") {
                  setIsActivated(
                      <div class="alert alert-danger" role="alert">
                      Please activate your account.
                      </div>
                  );
              }
  
          })
          .catch(err => {
            setIsActivated('');
          }) 
  }
  
    //const getList = event => {

    async function getList(event) { 

        $('body').attr('class','');
        $('.modal-backdrop').attr('class','');

await axios.get(
            'https://mobiapp.mahalome.com/user/saved-receiver', {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/x-www-form-urlencoded',
                'token': jwt
                // 'content-type': 'application/x-www-form-urlencoded'
            }
        })
.then(async (res) => {
            setReceiverList(res.data.data.ReceiverList);
for (let i=0; i<res.data.data.ReceiverList.length; i++) {
              res.data.data.ReceiverList[i].profilepicture =  await checkImage(res.data.data.ReceiverList[i].system_id);
            } 

            setResult(
                <div class="alert alert-success" role="alert">
                    {res.data.resultDetail}
                </div>
            );
            setRedirect(1);
        })
        .catch(err => {
            setResult(
                <div className="alert alert-danger" role="alert">
                    Temporary Error Occured
                </div>
            );
        })
    }

const checkImage = (systemId) => {
        
        var src = "https://mahalomeapi.telcomart.com/profilepicture/"+systemId+".jpeg";

        return new Promise((resolve, reject) => {
          imageExists(src, function (exists) {
            if (exists) {
              resolve(
                "https://mobiapp.mahalome.com/profilepicture/" +
                  systemId +
                  ".jpeg"
              );
            } else {
              resolve("https://login.mahalome.com/img/no-image.png");
            }
          });
        });
      };      
const handleSubmitDeleteReceiver = event => {

    setGosterGostermeDelete('');
    $('body').attr('class','');
    $('.modal-backdrop').attr('class','');
    $('#DeleteReceiver').attr("style", "display:none");
    event.preventDefault();

    console.log(jwt+"***"+receiverIndexId);

    axios({
      method: "delete",
      url: "https://mobiapp.mahalome.com/user/saved-receiver",
      params: {
        system_id: receiverIndexId
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        token: jwt
      }
    })
      .then(res => {
        setResult(
          <div className="alert alert-danger" role="alert">Removed from your list.</div>
        );
        getList();
      })
      .catch(err => {
        setResult(
          <div className="alert alert-danger" role="alert">Temporary Error Occured</div>
        );
      });

    getList();
}

const handleSubmit2 = event => {

  axios({
      method: 'post',
      url: 'https://mobiapp.mahalome.com/user/saved-receiver',
      params: {
          system_id:localStorage.getItem('targetId')
      },
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'token': jwt
          }
    }).then(res => { 
      getList();
      localStorage.setItem('targetId', '');
  })
  .catch(err => { 
      setResult(
          <div className="alert alert-danger" role="alert">
              Temporary Error Occured
          </div>
      );
  })
}

    const handleSubmit1 = event => {


        alert(newSystemId);

        setGosterGostermeAdd('');
        event.preventDefault();
        $('body').attr('class','');
        $('.modal-backdrop').attr('class','');
        event.preventDefault();
        
        axios({
            method: 'post',
            url: 'https://mobiapp.mahalome.com/user/saved-receiver',
            params: {
                system_id:newSystemId
            },
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'token': jwt
                }
          }).then(res => { 
            getList();
        })
        .catch(err => { 
            setResult(
                <div className="alert alert-danger" role="alert">
                    Temporary Error Occured
                </div>
            );
        })
    }
 
  // Redirect if there is token
	if(authCheck!=="Authenticated" ){
    return <Redirect to="/login" />
  }
    return (
        
        <div>
            <Helmet>
            <meta charSet="utf-8" />
            <title>Saved List | Mahalome</title>
            <meta name="description" content="Send Money" />
            </Helmet>
            <LoadingOverlay active={load} spinner> 

            <Header />

            <div class="spacer-25"></div>

            <div className="col-md-12">
                <h1 className="mt-5">Saved Receiver List</h1>
            </div>
            {isActivated}

        <div className="container">
          <div className="row background ">
            <div className="col-md-12">
                <div className="form-group login-form">
                  <table class="table">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col">IMAGE</th>
                        <th scope="col">NAME</th>
                        <th scope="col">OPERATION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {receiverList ? (
                        receiverList.map((receiverListValue, index) => (
                          <tr>

{/* <td><i class="fa fa-camera-retro fa-lg" aria-hidden="true"></i></td> */}
                            {/* <img src={`https://mahalomeapi.telcomart.com/profilepicture/${receiverListValue.system_id}.jpeg`} /> */}
 
                            <td>
                              <div className="profileBorder">
                           
                                <img src={receiverListValue.profilepicture} />
                              </div> 
                            </td>
                            
                            <td>{receiverListValue.name} {receiverListValue.surname} ({receiverListValue.system_id}) </td> 
                            <td style={{'textAlign': 'center'}}> 
                            
                            <a href={`/send-money/${receiverListValue.system_id}`}><i class="fa fa-usd" aria-hidden="true"></i></a>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            
                            <a href="#" data-toggle="modal" data-target="#DeleteReceiver" onClick={() => setReceiverIndexId(receiverListValue.system_id)}> 
                            <i className="fa fa-trash" aria-hidden="true"></i> 
                            </a>

                            </td>
                          </tr>
                        ))
                      ) : (
                        <div></div>
                      )}
                    </tbody>
                </table>
        </div>
        </div>
        </div>
        </div>

        <div className="container text-center">
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group login-form">
                    <button
                    type="button"
                    className="btn btn-success fullwidth"
                    data-toggle="modal"
                    data-target="#myModal1"
                    >
                    Add New Reciever
                    </button>
                    </div>
                </div>
            </div>
        </div>

            <div className="spacer-25"></div>

            </LoadingOverlay>
            <Footer />   

<div id="myModal1" className={`modal fade ${gosterGostermeAdd}`} role="dialog">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">Add New Receiver</h4>
      </div>
      <div className="modal-body">

    <form className="user" onSubmit={handleSubmit1}>

        <div className="form-group login-form">
        <label for="exampleInputEmail1 form-text-style"><span className="form-text-style">User Id</span></label>
        <input type="text" value={newSystemId} required onChange={(e) => { setNewSystemId(e.target.value) }} className="form-control form-control-user" placeholder="User Id" />
        </div>

        <div className="form-group login-form">
            <button type="submit" className="btn btn-primary fullwidth">Save Receiver</button>
        </div>

    </form>

      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div id="DeleteReceiver" className={`modal fade ${gosterGostermeDelete}`} role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Delete Receiver List</h4>
            </div>
            <div className="modal-body">
              <form className="user" onSubmit={handleSubmitDeleteReceiver}>

                <div className="form-group login-form">
                  <label for="exampleInputEmail1 form-text-style">
                    <span className="form-text-style">Deleting Receiver, are you sure ?</span>
                  </label> 
                </div>

                <div className="form-group login-form">
                <button
                    type="submit"
                    className="btn btn-danger"
                  >
                    Delete
                  </button>
                  <button
                    data-dismiss="modal"
                    className="btn btn-info float-right"
                  >
                    Cancel
                  </button>

                </div>
              </form>
            </div>
            <div className="modal-footer">
            </div>
          </div>
        </div>
      </div>



</div>
 
    );
};

export default SavedList;
