import React, {useState,useEffect} from 'react';
import { NavLink } from 'react-router-dom';

const Header = () => {

	const [activeClass, setActiveClass] = useState('');

	const [mainMenu, setMainMenu] = useState();

    useEffect(() => {

	}, []);
	return (

        <header> 
        <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
          <a className="navbar-brand" href="/login">Mahalome</a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav mr-auto">
  
              <li className="nav-item">
                <a className="nav-link" href="/about">About</a>
              </li>
  
              <li className="nav-item">
                <a className="nav-link" href="/help">Help</a>
              </li>
  
            </ul>
          </div>
        </nav>
      </header>
);
}

export default Header;
