import React,{useEffect, useState} from "react";
import { Link, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';
import axios from 'axios';

import Header from "./includes/header";
import Footer from "./includes/footer";
 
const SendMoneyVerification = () => {

    localStorage.setItem('whereAmI', "Main");

    const [jwt, setJwt] = useState(localStorage.getItem('jwt'));

    const [load, setLoad] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [result, setResult] = useState('');
    const [redirect, setRedirect] = useState(0);
 
    const [balance, setBalance] = useState(0);
    const [bonus, setBonus] = useState(0);
    const [tips, setTips] = useState(0);
    const [transfer, setTransfer] = useState(0);

    const [isActivated, setIsActivated] = useState('');

    const [targetSystemId, setTargetSystemId] = useState(0);
    const [serviceAmount, setserviceAmount] = useState(0);
    
    const [buttonDisabled, setButtonDisabled] = useState("");
    const [redirectToReceiver, setRedirectToReceiver] = useState(0);

    const [authCheck, setAuthCheck] = useState('Authenticated');

    useEffect(() => {

        setLoad(false);
        checkActivation();
        getUserBalance();

        axios.get(
            'https://mobiapp.mahalome.com/user/auth/check-session', {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/x-www-form-urlencoded',
                'token': jwt,
                'pageName': 'SendMoneyVerification'
            }
            })
            .then(res => { 
            setAuthCheck(res.data.result);
            })
            .catch(err => {
              setAuthCheck('401');
            }) 

	}, []);
    

    const handleSubmitSend = event => {

        event.preventDefault();
    
        axios({
            method: 'post',
            url: 'https://mobiapp.mahalome.com/user/wallet/transfer/user-to-user',
            params: {
                targetId:localStorage.getItem('targetId'),
                amount:localStorage.getItem('sendingAmount')
            },
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'token': jwt
                }
          }).then(res => {
            console.log( JSON.stringify(res.data.resultDetail) );
            setResult(
                <div className="alert alert-danger" role="alert">
                   {res.data.resultDetail}
                </div>
            );
            
            setButtonDisabled(
                <div>
                <button type="submit" disabled className="btn btn-primary">Send Money</button>
                </div>
            );

        })
        .catch(err => {
            console.log( JSON.stringify(err) );
            setResult(
                <div className="alert alert-danger" role="alert">
                    Temporary Error Occured. Please try again later.
                </div>
            );

            setButtonDisabled(
                <div>
                <button type="submit" disabled className="btn btn-primary">Send Money</button>
                </div>
            );
            
        })
    }

    const handleSubmitSave = event => {
        setRedirectToReceiver(1);
    }

    const checkActivation = () => {
        axios.get(
            'https://mobiapp.mahalome.com/user/checkActivation', {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/x-www-form-urlencoded',
                'token': jwt
            }
            })
            .then(res => { 
             // console.log("***********!!!!"+ JSON.stringify(res.data.data.UserStatus[0].status) );
    
                if (res.data.data.UserStatus[0].status === "PASSIVE") {
                    setIsActivated(
                        <div class="alert alert-danger" role="alert">
                        Please activate your account.
                        </div>
                    );
                }
    
            })
            .catch(err => {
                setIsActivated('');
            }) 
    }

    if( redirectToReceiver === 1 ){
        return <Redirect to="/saved-list" />
    }

const getUserBalance = event => {
   
    axios.get(
        'https://mobiapp.mahalome.com/user/wallet-all', {
        headers: {
            'accept': 'application/json',
            'content-type': 'application/x-www-form-urlencoded',
            'token': jwt
        }
    })
    .then(res => {
        setBalance(res.data.data.walletHistory[0].wallet_money);
    
        if ( parseInt(res.data.data.walletHistory[0].wallet_money) < parseInt(localStorage.getItem('sendingAmount')) ) {
            setButtonDisabled(
                <div>
                <div><small className="form-text text-muted text-center"><i className="fas fa-sign-in-alt"></i>There is not enough amount in your wallet!</small></div>
                <button type="submit"  disabled  className="btn btn-primary">Send Money</button>
                </div>
        
            );
        } else {
            setButtonDisabled(
                <div>
                <button type="submit" className="btn btn-primary">Send Money</button>
                </div>
            );
        }
        setResult(
        );
    })
    .catch(err => {
    })

}

	// Redirect if there is token
	if(authCheck!=="Authenticated" ){
        return <Redirect to="/login" />
    }

    return (
        
        <div>
            <Helmet>
            <meta charSet="utf-8" />
            <title>Send Money Verification | Mahalome</title>
            <meta name="description" content="Send Money" />
            </Helmet>
            <LoadingOverlay active={load} spinner> 

            <Header />
            <div class="spacer-25"></div>

            <div className="col-md-12">
                <h1 className="mt-5">Send Money Verification</h1>
            </div>

            {isActivated}
            {result}
            
                <div className="main-form">
                <div className="row background transbox">
                    <div className="col-md-12">
                 

                    <div className="form-group login-form">

                    <div className="text-center">
                    <i class="fa fa-user fa-5x" aria-hidden="true"></i>
                    </div>

                    <div class="spacer-25"></div>

                        <div className="text-center">
                            <h2>{localStorage.getItem('nameSurname')}</h2>
                            <div>( {localStorage.getItem('targetId')} )</div>
                        </div>

                        <div class="spacer-25"></div>

                        <div className="text-center">
                            <h2>$ {localStorage.getItem('sendingAmount')}</h2>
                        </div>
                    </div>

                    <div className="form-group login-form text-center">
                        
                        <form className="user" onSubmit={handleSubmitSend}>
                        {buttonDisabled}
                        </form>
                        
                        <div className="spacer-25"></div>

                        <form className="user" onSubmit={handleSubmitSave}>
                        <button type="submit" className="btn btn-primary">Save for Later</button>
                        </form>
                    
                    </div>
 
                    <div className="spacer-25"></div>
                    </div>
                </div>
            </div>
 
            <div class="spacer-25"></div>

            </LoadingOverlay>
            <Footer />    
        </div>
 
    );
};

export default SendMoneyVerification;
