import React from 'react';
import { Route, Switch } from 'react-router-dom';

// import Main from './auth/Main';
import Login from './auth/Login';
import Logout from './auth/Logout';
import Register from './auth/Register';
import ForgotPassword from './auth/ForgotPassword';
import ResetPassword from './auth/ResetPassword';
import Activation from './auth/Activation';

import About from './info/About';
import Help from './info/Help';
import Privacy from './info/Privacy';

import Dashboard from './common/Dashboard';
import SendMoney from './common/SendMoney';
import SendMoneyVerification from './common/SendMoneyVerification';
import SavedList from './common/SavedList';
import ReceiveMoney from './common/ReceiveMoney';
import Balance from './common/Balance';
import BalanceHistory from './common/BalanceHistory';

import Profile from './common/Profile';
import BankAccounts from './common/BankAccounts';
import BankAccountsStripeAccount from './common/BankAccountsStripeAccount';
import ChangePassword from './common/ChangePassword';

import BankAccountsCards from './common/BankAccountsCards';
import RedirectId from './common/RedirectId';
 
import StripeCreditCard from './common/StripeCreditCard';
import WebStripeCreditCard from './common/WebStripeCreditCard';

import LoadMoney from './common/LoadMoney';
import WithdrawFunds from './common/WithdrawFunds';
 
const Routes = () => (
  <Switch>

    <Route exact path="/login" component={Login} />
    <Route exact path="/logout" component={Logout} />
    <Route exact path="/register" component={Register} />
    <Route exact path="/forgot-password" component={ForgotPassword} />
    
    <Route exact path="/reset-password/:id" component={ResetPassword} />
    <Route exact path="/activation/:id" component={Activation} />
    <Route exact path="/send-money-verification" component={SendMoneyVerification} />
    
    <Route path="/dashboard" component={Dashboard} />
    <Route path="/send-money/:receiverId?" component={SendMoney} />
    <Route path="/saved-list" component={SavedList} />
    <Route path="/receive-money" component={ReceiveMoney} />
    <Route path="/balance" component={Balance} />
    <Route path="/balance-history" component={BalanceHistory} />

    <Route path="/load-money" component={LoadMoney} />
    <Route path="/withdraw-funds" component={WithdrawFunds} />

    <Route path="/profile" component={Profile} />
    <Route path="/bank-accounts" component={BankAccounts} />
    <Route path="/bank-accounts-stripe-account" component={BankAccountsStripeAccount} />
    
    <Route path="/change-password" component={ChangePassword} />

    <Route path="/bank-accounts-cards" component={BankAccountsCards} />
    <Route path="/redirectId" component={RedirectId} />
    <Route path="/StripeCreditCard/:clientSecret" component={StripeCreditCard} />
    <Route path="/WebStripeCreditCard/:clientSecret" component={WebStripeCreditCard} />    

    
    <Route exact path="/about" component={About} />
    <Route exact path="/help" component={Help} />
    <Route exact path="/privacy" component={Privacy} />

  </Switch>
);

export default Routes;
