import React,{useEffect, useState} from "react";
import { Link, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';
import axios from 'axios';

import Header from "./includes/header";
import Footer from "./includes/footer";
 
const Balance = () => {

    const loadMoney = event => {
        setRedirectToLoadMoney(1);
      }
      
      const withDraw = event => {
        setRedirectToWithDraw(1);
      }
    
    localStorage.setItem('whereAmI', "Main");


    const [redirectToLoadMoney, setRedirectToLoadMoney] = useState(0);
    const [redirectToWithDraw, setRedirectToWithDraw] = useState(0);
    const [jwt, setJwt] = useState(localStorage.getItem('jwt'));

    const [load, setLoad] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [result, setResult] = useState('');
    const [redirect, setRedirect] = useState(0);
 
    const [walletMoney, setWalletMoney] = useState(0);
    const [walletBonus, setWalletBonus] = useState(0);
    const [mustSpent, setMustSpent] = useState(0);

    const [isActivated, setIsActivated] = useState('');

    const [authCheck, setAuthCheck] = useState('Authenticated');
    
    useEffect(() => {

        setLoad(false);

        checkActivation();

        axios.get(
            'https://mobiapp.mahalome.com/user/auth/check-session', {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/x-www-form-urlencoded',
                'token': jwt,
                'pageName': 'Balance'
            }
            })
            .then(res => { 
            setAuthCheck(res.data.result);
            })
            .catch(err => {
              setAuthCheck('401');
            }) 

        console.log(jwt);

        axios.get(
            'https://mobiapp.mahalome.com/user/wallet-all', {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/x-www-form-urlencoded',
                'token': jwt
                // 'content-type': 'application/x-www-form-urlencoded'
            }
        })
        .then(res => {
        console.log(res.data.data.walletHistory[0]);
            setWalletMoney(res.data.data.walletHistory[0].wallet_money);
            setWalletBonus(res.data.data.walletHistory[0].wallet_bonus);
            setMustSpent(res.data.data.walletHistory[0].must_spent);

            setResult(
            );
            setRedirect(1);
    
        })
        .catch(err => {

            setResult(
            );
            
        })

	}, []);


const checkActivation = () => {
    axios.get(
        'https://mobiapp.mahalome.com/user/checkActivation', {
        headers: {
            'accept': 'application/json',
            'content-type': 'application/x-www-form-urlencoded',
            'token': jwt
        }
        })
        .then(res => { 
         // console.log("***********!!!!"+ JSON.stringify(res.data.data.UserStatus[0].status) );

            if (res.data.data.UserStatus[0].status === "PASSIVE") {
                setIsActivated(
                    <div class="alert alert-danger" role="alert">
                    Please activate your account.
                    </div>
                );
            }

        })
        .catch(err => {
            setIsActivated('');
        }) 
}

    const handleSubmit = event => {

        event.preventDefault();
    
        // set value
        setEmail(email);
        setPassword(password);
    
        axios.post('https://mobiapp.mahalome.com/admin/login',{
            email: email,
            password: password
        })
        .then(res => {
    
            localStorage.setItem('token', res.data.jwt);
            console.log(localStorage.getItem('token'));
    
            setResult(
                <div class="alert alert-success" role="alert">
                    {res.data.resultDetail}
                </div>
            );
            setRedirect(1);
    
        })
        .catch(err => {
            
            console.log(err.message);
            
            setResult(
                <div class="alert alert-danger" role="alert">
                    Email or password is incorrect
                </div>
            );
        })
    }

	// Redirect if there is token
	if(authCheck!=="Authenticated" ){
        return <Redirect to="/login" />
    }

if( redirectToLoadMoney === 1 ){
        return <Redirect to="/load-money" />
      }
    
      if( redirectToWithDraw === 1 ){
        return <Redirect to="/withdraw-funds" />
      }
    return (
        
        <div>
            <Helmet>
            <meta charSet="utf-8" />
            <title>Send Money | Mahalome</title>
            <meta name="description" content="Send Money" />
            </Helmet>
            <LoadingOverlay active={load} spinner> 

            <Header />
            <div class="spacer-25"></div>

            <div className="col-md-12">
                <h1 className="mt-5">Balance</h1>
            </div>

            {isActivated}
            
                <div className="main-form">
                <div className="row background transbox">
                    {/* left spacer */}
                    <div className="col-md-2"></div>
                    <div className="col-md-8">

                    <form className="user" onSubmit={handleSubmit}>
                    <div className="form-group login-form">

                    <table width="100%" style={{'text-align': 'center'}}>
                            <tr>
                            

                    <div className="form-group login-form text-center form-text-style">
                       Wallet Money!
                    </div>
                     
                    <div className="form-group login-form text-center">
                       <h2><i class="fa fa-usd" aria-hidden="true"></i> {walletMoney}</h2>
                    </div>

                    <div className="form-group login-form text-center form-text-style">
                       Wallet Bonus
                    </div>
                     
                    <div className="form-group login-form text-center">
                       <h2><i class="fa fa-usd" aria-hidden="true"></i> {walletBonus}</h2>
                    </div>

                    <div className="form-group login-form text-center form-text-style">
                       Must Spent
                    </div>
                     
                    <div className="form-group login-form text-center">
                       <h2><i class="fa fa-usd" aria-hidden="true"></i> {mustSpent}</h2>
                    </div>


                            </tr>
                            <div class="spacer-25"></div>
 
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>
                        <a href="/balance-history" className="btn btn-primary fullwidth">Go to History</a>
                        </td>
                            </tr>

                        </table>

                    </div>





<div className="row">

                    <div className="col">

                      <form className="user" onSubmit={loadMoney}>
                      <div className="form-group login-form">
                      <button type="submit" className="btn btn-primary fullwidth">Load Money</button>
                      </div>
                    </form>
</div>

                      <div className="col">
                      <form className="user" onSubmit={withDraw}>
                      <div className="form-group login-form">
                      <button type="submit" className="btn btn-primary fullwidth">Withdraw Funds</button>
                      </div>
                      </form>
                      </div>
 
                </div>
         


                    <div className="form-group login-form">
                    {result}
                    </div>

                    </form>
                    <div className="spacer-25"></div>
                    <div className="spacer-25"></div>
                    </div>
                    {/* center-8 ... */}
                    {/* right spacer */}
                    <div className="col-md-2"></div>

                </div>
            </div>
 
            <div class="spacer-25"></div>

            </LoadingOverlay>
            <Footer />    
        </div>
 
    );
};

export default Balance;
