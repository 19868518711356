import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import LoadingOverlay from "react-loading-overlay";
import axios from "axios";

import Header from "../auth/includes/header";
import Footer from "../auth/includes/footer";

const About = () => {
  localStorage.setItem("whereAmI", "Main");
  const [token, setToken] = useState();

  const [load, setLoad] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [result, setResult] = useState("");
  const [redirect, setRedirect] = useState(0);
  const [editorData, setEditorData] = useState('');

  useEffect(() => {
    setLoad(false);

    axios.get(
      'https://mobiapp.mahalome.com/user/usermanagement/staticpage/help', {
      headers: {
          'accept': 'application/json',
          'content-type': 'application/x-www-form-urlencoded'
      }
      })
      .then(res => { 
      setEditorData(res.data.resultDetail)
      })
      .catch(err => {
      }) 


    if (localStorage.getItem("token")) {
      setToken(localStorage.getItem("token"));
    } else {
      setToken("not-available");
    }

    // Yeri burası mı ?
    if (token === "not-available") {
      return <Redirect to="/" />;
    }
  }, []);

   

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Help | Mahalome</title>
        <meta name="description" content="Help" />
      </Helmet>
      <LoadingOverlay active={load} spinner>
        <div class="spacer-90"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <img src="./img/logo.png" height="60" />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 transbox mx-auto">
                <div className="form-group login-form">
                <a href="/login"><i class="fa fa-long-arrow-left mb-4" aria-hidden="true"></i> Back</a>

                <div className="content" dangerouslySetInnerHTML={{__html: editorData}}></div>
                  <p>
                    <a href="mailto:contact@mahalome.com" className="mr-4">
                      contact@mahalome.com
                    </a>
                  </p>
                </div>

            </div>
          </div>
        </div>
      </LoadingOverlay>
      <div class="spacer-25"></div>
      <Footer />
    </div>
  );
};

export default About;
