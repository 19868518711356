

import React, {useState,useEffect} from 'react';
import { NavLink } from 'react-router-dom';

const Footer = () => {

	const [activeClass, setActiveClass] = useState('');

	const [mainMenu, setMainMenu] = useState();

    useEffect(() => {

	}, []);
	return (
    <footer className="footer">
        <div className="container-fluid">
        <div className="row"> 
          <div className="col footer-item">
          <a href="/send-money"><i className="fa fa-circle" aria-hidden="true"></i> Send</a>
          </div>
          <div className="col footer-item">
          <a href="/receive-money"><i className="fa fa-circle" aria-hidden="true"></i> Receive</a>
          </div>
          <div className="col footer-item">
          <a href="/balance"><i className="fa fa-circle" aria-hidden="true"></i> Wallet</a>
          </div>
          <div className="col footer-item">
          <a href="/saved-list"><i className="fa fa-circle" aria-hidden="true"></i> Saved</a>
          </div>
        </div>
        </div>
    </footer>
);

}

export default Footer;

