import React, { useEffect, useState } from "react";
import axios from "axios";
  
import { Helmet } from "react-helmet";
import LoadingOverlay from "react-loading-overlay";

import Header from "./includes/header";
import Footer from "./includes/footer";

import {isMobile} from 'react-device-detect';

const RedirectId = props => {

  const [load, setLoad] = useState(true);
  localStorage.setItem("whereAmI", "Main");
  
  let { history } = { ...props}  ;
  let myVar = JSON.stringify(history.location.search);

  myVar = myVar.replace('"', '');
  myVar = myVar.replace('"', '');

  var myVarArr = myVar.split("&");

  var urlCode = myVarArr[0].replace("?code=", "");
  var urlState = myVarArr[1].replace("state=", "");

  useEffect(() => {
    
    setLoad(false);
    
    axios({
      method: "post",
      url: "https://mobiapp.mahalome.com/user/service/stripe-connected-account",
      params: {
        code:urlCode,
        state:urlState
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(res => {
        console.log( JSON.stringify(res.data.resultDetail) );
      })
      .catch(err => {
      });
  }, []);
 
  if (isMobile) {
    return (
                  <div>
                          {/* <div className="spacer-90"></div> */}
                          <div className="spacer-25"></div>
                  
                          <div className="container text-center">
                            <div className="row">
                              <div className="col-md-12">
                                <h1 className="mt-5">Bank Accounts</h1>
                                <div className="spacer-30"></div>
                                <h6>Creation Completed.</h6>
                              </div>
                            </div>
                          </div>
                  
                          <div className="spacer-25"></div>
                          <div className="container">
                            <div className="row background ">
                              <div className="col-md-12">
                                  <div className="form-group login-form">
                            Your banking operation is completed!
                          </div>
                          </div>
                          </div>
                          </div>
                                  <div className="container text-center">
                                    <div className="row">
                                  </div>
                                </div>
                  
                          <div className="spacer-25"></div>
                  
                    </div>      
                  
                  );

  } else {


    return (
      <div>
          <Helmet>
          <meta charSet="utf-8" />
          <title>BankAccounts | Mahalome</title>
          <meta name="description" content="Profile" />
          </Helmet>
          <LoadingOverlay active={load} spinner>
      
          <Header />   
          <div>
          <div className="spacer-90"></div>
          <div className="spacer-25"></div>
      
          <div className="container text-center">
          <div className="row">
          <div className="col-md-12">
          <h1 className="mt-5">Bank Accounts</h1>
          <div className="spacer-30"></div>
          <h6>Creation Completed.</h6>
          </div>
          </div>
          </div>
      
          <div className="spacer-25"></div>
          <div className="container">
          <div className="row background ">
          <div className="col-md-12">
          <div className="form-group login-form">
          Your banking operation is completed!
          </div>
          </div>
          </div>
          </div>
          <div className="container text-center">
          <div className="row">
          </div>
          </div>
          <div className="spacer-25"></div>
          </div>  
          <div className="spacer-25"></div>

          </LoadingOverlay>
          <Footer />

        </div> 
      );
 
  }

};
export default RedirectId;
