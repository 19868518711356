import React,{useEffect, useState} from "react";
import { Link, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';
import axios from 'axios';

import Header from "./includes/header";
import Footer from "./includes/footer";
 
const Logout = () => {

    localStorage.setItem('whereAmI', "Main");
    localStorage.setItem('jwt', '');

    const [token, setToken] = useState();
 
    const [load, setLoad] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [result, setResult] = useState('');
    const [redirect, setRedirect] = useState(0);

    useEffect(() => {

        setLoad(false);

        if (localStorage.getItem('token')) {
            setToken(localStorage.getItem('token'));
        } else {
            setToken("not-available");
        }

        // Yeri burası mı ? 
        if(token === "not-available"){
            return <Redirect to="/" />
        }

	}, []);
    
    const handleSubmit = event => {

        event.preventDefault();
    
        // set value
        setEmail(email);
        setPassword(password);
    
        axios.post('https://mobiapp.mahalome.com/admin/login',{
            email: email,
            password: password
        })
        .then(res => {
    
            localStorage.setItem('token', res.data.jwt);
            console.log(localStorage.getItem('token'));
    
            setResult(
                <div class="alert alert-success" role="alert">
                    {res.data.resultDetail}
                </div>
            );
            setRedirect(1);
    
        })
        .catch(err => {
            
            console.log(err.message);
            
            setResult(
                <div class="alert alert-danger" role="alert">
                    Email or password is incorrect
                </div>
            );
            
        })
    }
    
        


    return (
        <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Login Mahalome | Mahalome</title>
          <meta name="description" content="Login Mahalome System" />
        </Helmet>
        <LoadingOverlay active={load} spinner>
          <div class="spacer-90"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <img src="./img/logo.png" height="60" />
              </div>
            </div>
          </div>
  
          <div class="spacer-30"></div>
  
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <div className="main-form">
                  <div className="row background transbox">
                    <div className="col-md-12 shadow">
                      <form className="user" onSubmit={handleSubmit}>
                         
                        <div className="spacer-25"></div>
                        <div className="container form-link">

                        <table width="100%" style={{'text-align': 'center'}}>
                            <div className="row">
                                <div className="col">
                                    Successfully
                                    <br/><br/>
                                    LOGGED OUT!
                                </div>
                            </div>   
                            </table>

                    <div className="spacer-25"></div>
                    <div className="spacer-25"></div>
                    <div className="spacer-25"></div>
                    <div className="spacer-25"></div>

                          <div className="row"> 
                          <div className="col">
                              <a href="/login">Login</a>
                            </div>
                            <div className="col text-right">
                              <a href="forgot-password">Forgot Password</a>
                            </div>
                          </div>

                        </div>
                      </form>
                      <div className="spacer-25"></div>
                      <div className="spacer-25"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="spacer-25"></div>
        </LoadingOverlay>
        <Footer />
      </div>
 
    );
};

export default Logout;
