import React,{useEffect, useState} from "react";
import { Link, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';
import axios from 'axios';

import Header from "./includes/header";
import Footer from "./includes/footer";
 
const ForgotPassword = () => {

    localStorage.setItem('whereAmI', "Main");
    const [token, setToken] = useState();
 
    const [load, setLoad] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [result, setResult] = useState(0);
    const [redirect, setRedirect] = useState(0);

    useEffect(() => {

        setLoad(false);

        if (localStorage.getItem('token')) {
            setToken(localStorage.getItem('token'));
        } else {
            setToken("not-available");
        }

        // Yeri burası mı ? 
        if(token === "not-available"){
            return <Redirect to="/" />
        }

	}, []);
    
    const handleSubmit = event => {

        event.preventDefault();

        axios.post('https://mobiapp.mahalome.com/user/forgotPassword',{
            email: email
        })
        .then(res => {    
            setResult(1);
        })
        .catch(err => {
            
            console.log(err.message);
            
            setResult(
                <div class="alert alert-danger" role="alert">
                    Email or password is incorrect
                </div>
            );
            
        })
    }
    
if (result===0) {

    return (
        
        <div>
            <Helmet>
            <meta charSet="utf-8" />
            <title>Forgot Password | Mahalome</title>
            <meta name="description" content="Forgot Password" />
            </Helmet>
            <LoadingOverlay active={load} spinner> 
            <Header />
            <div class="spacer-25"></div>
            <div className="col-md-12">
                <h1 className="mt-5">Forgot Password</h1>
            </div>
                <div className="main-form">
                <div className="row background transbox">
                    <div className="col-md-12">
                    <form className="user" onSubmit={handleSubmit}>
                        <div className="form-group login-form">
                        <label for="exampleInputEmail1 form-text-style"><span className="form-text-style">Email address</span></label>
                        <input type="email" value={email} required onChange={(e) => { setEmail(e.target.value) }} className="form-control form-control-user" placeholder="Email" />
                        <small className="form-text text-muted">
                            <i className="fas fa-sign-in-alt"></i>Please write your email, if there is an account matching to this email, we will send a reset passwod link to you.</small>
                        </div> 
                        <div className="form-group login-form">
                        <button type="submit" className="btn btn-primary fullwidth">Submit</button>
                        </div> 
                    </form>
                    <div className="spacer-25"></div>
                    <div className="spacer-25"></div>
                    </div>
                </div>
            </div>
            <div class="spacer-25"></div>
            </LoadingOverlay>
            <Footer />    
        </div>
 
    );
} else {
return (
        
    <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Forgot Password | Mahalome</title>
      <meta name="description" content="Forgot Password Mahalome System" />
    </Helmet>
    <LoadingOverlay active={load} spinner>
      <div class="spacer-90"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <img src="./img/logo.png" height="60" />
          </div>
        </div>
      </div>

      <div class="spacer-30"></div>

      <div className="container">
        <div className="row">
          <div className="col-lg-6 mx-auto">
            <div className="main-form">
              <div className="row background transbox">
                <div className="col-md-12 shadow">
                  <form className="user">
                     
                    <div className="spacer-25"></div>
                    <div className="container form-link">

                    <table width="100%" style={{'text-align': 'center'}}>
                        <div className="row">
                            <div className="col">
                                
                                <br/><br/>
                                PLEASE CHECK YOUR EMAIL!
                            </div>
                        </div>   
                        </table>

                <div className="spacer-25"></div>
                <div className="spacer-25"></div>
                <div className="spacer-25"></div>

                      <div className="row"> 
                      <div className="col" style={{'textAlign': 'center'}}>
                          <a href="/login">Login</a>
                      </div>
                      </div>

                    </div>
                  </form>
                  <div className="spacer-25"></div>
                  <div className="spacer-25"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="spacer-25"></div>
    </LoadingOverlay>
    <Footer />
  </div>        
   
    );
} 
    
};

export default ForgotPassword;
