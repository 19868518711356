import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import LoadingOverlay from "react-loading-overlay";
import axios from "axios";

import Header from "./includes/header";
import Footer from "./includes/footer";

import $ from 'jquery';

const BankAccounts = () => {
  localStorage.setItem("whereAmI", "Main");
  const [jwt, setJwt] = useState(localStorage.getItem("jwt"));

  const [load, setLoad] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [result, setResult] = useState("");
  const [redirect, setRedirect] = useState(0);

  const [bankList, setBankList] = useState([]);
  const [cardList, setCardList] = useState([]);

  const [newCardHolderName, setNewCardHolderName] = useState("");
  const [newCardNumber, setNewCardNumber] = useState("");
  const [newCvcNumber, setNewCvcNumber] = useState("");
  const [newExpireDate, setNewExpireDate] = useState("");

  const [newIban, setNewIban] = useState("");
  const [newBankName, setNewBankName] = useState("");

  const [creditCardIndexNumber, setCreditCardIndexNumber] = useState(0);
  const [bankAccountIndexNumber, setBankAccountIndexNumber] = useState(0);

  const [gosterGostermeBank, setGosterGostermeBank] = useState('show');
  const [gosterGostermeCard, setGosterGostermeCard] = useState('show');
  const [gosterGostermeMyModal1, setGosterGostermeMyModal1] = useState('show');
  const [gosterGostermeMyModal2, setGosterGostermeMyModal2] = useState('show');

  const [redirectToLoadMoney, setRedirectToLoadMoney] = useState(0);
  const [redirectToWithDraw, setRedirectToWithDraw] = useState(0);

  const [authCheck, setAuthCheck] = useState('Authenticated');
  const [isActivated, setIsActivated] = useState('');

  const [stateToken, setStateToken] = useState('');

  useEffect(() => {
  
      getList();
      
      checkActivation();

      getStripeStateToken();

      setLoad(false);

      axios.get(
        'https://mobiapp.mahalome.com/user/auth/check-session', {
        headers: {
            'accept': 'application/json',
            'content-type': 'application/x-www-form-urlencoded',
            'token': jwt,
            'pageName': 'BankAccounts'
        }
        })
        .then(res => { 
        setAuthCheck(res.data.result);
        })
        .catch(err => {
          setAuthCheck('401');
        }) 

  }, []);


  const checkActivation = () => {
    axios.get(
        'https://mobiapp.mahalome.com/user/checkActivation', {
        headers: {
            'accept': 'application/json',
            'content-type': 'application/x-www-form-urlencoded',
            'token': jwt
        }
        })
        .then(res => { 
         // console.log("***********!!!!"+ JSON.stringify(res.data.data.UserStatus[0].status) );

            if (res.data.data.UserStatus[0].status === "PASSIVE") {
                setIsActivated(
                    <div class="alert alert-danger" role="alert">
                    Please activate your account.
                    </div>
                );
            }

        })
        .catch(err => {
          setIsActivated('');
        }) 
}


  const getList = event => {
// Banks
axios
.get("https://mobiapp.mahalome.com/user/bank", {
  headers: {
    accept: "application/json",
    "content-type": "application/x-www-form-urlencoded",
    token: jwt
    // 'content-type': 'application/x-www-form-urlencoded'
  }
})
.then(res => {
  setBankList(res.data.data.Banks);
  setResult(
    <div class="alert alert-success" role="alert">
      {res.data.resultDetail}
    </div>
  );
  setRedirect(1);
})
.catch(err => {
  setResult(
    <div className="alert alert-danger" role="alert">
      Temporary Error Occured
    </div>
  );
});
// Credit Cards
axios
.get("https://mobiapp.mahalome.com/user/creditcard", {
  headers: {
    accept: "application/json",
    "content-type": "application/x-www-form-urlencoded",
    token: jwt
    // 'content-type': 'application/x-www-form-urlencoded'
  }
})
.then(res => {
  setCardList(res.data.data.CreditCards);
  setResult(
    <div class="alert alert-success" role="alert">
      {res.data.resultDetail}
    </div>
  );
  setRedirect(1);
})
.catch(err => {
  setResult(
    <div className="alert alert-danger" role="alert">
      Temporary Error Occured
    </div>
  );
});
  }

  const loadMoney = event => {
    setRedirectToLoadMoney(1);
  }
  
  const withDraw = event => {
    setRedirectToWithDraw(1);
  }


  
  const getStripeStateToken = event => {

      axios({
        method: "post",
        url: "https://mobiapp.mahalome.com/user/service/get-stripe-state-token",
        params: {
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          token: jwt
        }
      })
        .then(res => {
          console.log( JSON.stringify(res.data.resultDetail) );
          setStateToken(res.data.resultDetail);
        })
        .catch(err => {
          setResult(
            <div className="alert alert-danger" role="alert">
              Temporary Error Occured
            </div>
          );
        });
  }

  const handleSubmit1 = event => {
    
    event.preventDefault();
    setGosterGostermeMyModal1('');
    $('body').attr('class','');
    $('.modal-backdrop').attr('class','');

    axios({
      method: "post",
      url: "https://mobiapp.mahalome.com/user/bank",
      params: {
        iban: newIban,
        name: newBankName,
        bank_id: 22
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        token: jwt
      }
    })
      .then(res => {
        setRedirect(1);
        getList();
        // setGosterGostermeMyModal1('');
      })
      .catch(err => {
        setResult(
          <div className="alert alert-danger" role="alert">
            Temporary Error Occured
          </div>
        );
      });
  };

  const handleSubmit2 = event => {
    
    setGosterGostermeMyModal2('');
    $('body').attr('class','');
    $('.modal-backdrop').attr('class','');
    event.preventDefault();
    
    axios({
      method: "post",
      url: "https://mobiapp.mahalome.com/user/creditcard",
      params: {
        cardno: newCardNumber,
        cardholdername: newCardHolderName,
        cvcnumber: newCvcNumber,
        expiredate: newExpireDate
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        token: jwt
      }
    })
      .then(res => {
        setRedirect(1);
        getList();
      })
      .catch(err => {
        setResult(
          <div className="alert alert-danger" role="alert">
            Temporary Error Occured
          </div>
        );
      });
      
  };


  const handleSubmitDeleteBankAccount = event => {
  
    setGosterGostermeBank('');
    $('body').attr('class','');
    $('.modal-backdrop').attr('class','');
    $('#DeleteBankAccount').attr("style", "display:none");
    event.preventDefault();
 
    axios({
      method: "delete",
      url: "https://mobiapp.mahalome.com/user/bank",
      params: {
        id: bankAccountIndexNumber
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        token: jwt
      }
    })
      .then(res => {
        setResult(
          <div className="alert alert-danger" role="alert">Bank Account Deleted</div>
        );
        getList();
      })
      .catch(err => {
        setResult(
          <div className="alert alert-danger" role="alert">Temporary Error Occured</div>
        );
      });
 
  }

  const handleSubmitDeleteCreditCard = event => {
 
    setGosterGostermeCard('');
    $('body').attr('class','');
    $('.modal-backdrop').attr('class','');
    $('#DeleteCreditCard').attr("style", "display:none");
    event.preventDefault();

    axios({
      method: "delete",
      url: "https://mobiapp.mahalome.com/user/creditcard",
      params: {
        id: creditCardIndexNumber
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        token: jwt
      }
    })
      .then(res => {
        setResult(
          <div className="alert alert-danger" role="alert">Credit Card Deleted</div>
        );
        getList();
      })
      .catch(err => {
        setResult(
          <div className="alert alert-danger" role="alert">Temporary Error Occured</div>
        );
      });
 
  }

  if( redirectToLoadMoney === 1 ){
    return <Redirect to="/load-money" />
  }

  if( redirectToWithDraw === 1 ){
    return <Redirect to="/withdraw-funds" />
  }

	// Redirect if there is token
	if(authCheck!=="Authenticated" ){
    return <Redirect to="/login" />
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>BankAccounts | Mahalome</title>
        <meta name="description" content="Profile" />
      </Helmet>
      <LoadingOverlay active={load} spinner>
        <Header />
        
        {/* <div className="spacer-90"></div> */}
        <div className="spacer-25"></div>

        <div className="container text-center">
          <div className="row">
            <div className="col-md-12">
              <h1 className="mt-5">Bank Accounts</h1>
              <div className="spacer-30"></div>
              <h6>CREDIT CARDS FOR SEND PAYMENT</h6>
            </div>
          </div>
          
        </div>

        {isActivated}
        

        <div className="container">
          <div className="row background ">
          
          <div className="col-md-12">
          <div className="form-group login-form">

 
          
<a href={`https://connect.stripe.com/express/oauth/authorize?response_type=code&client_id=ca_EA3GzFOK3jMHAbqebXmkAfDLgpnHNPK4&state=${stateToken}&suggested_capabilities[]=transfers&scope=read_write#/`} className="btn btn-success fullwidth">
Create New Stripe Bank Account
</a>
       
          </div>
          </div>

            {/* <div className="col-md-12">
                <div className="form-group login-form">
                  <table class="table">
                    <thead class="thead-dark">
                      <tr> 
                        <th scope="col">NAME</th>
                        <th scope="col">CARD NO</th> 
                        <th scope="col">OPERATION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cardList ? (
                        cardList.map((cardListValue, index) => (
                          <tr> 
                            <td>{cardListValue.cardholdername}</td>
                            <td>{cardListValue.cardno}</td>
                            <td style={{'textAlign': 'center'}}> 
                            <a href="#"><i className="fa fa-pencil" aria-hidden="true"></i></a>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <a href="#" data-toggle="modal" data-target="#DeleteCreditCard" onClick={() => setCreditCardIndexNumber(cardListValue.id)}> <i className="fa fa-trash" aria-hidden="true"></i> </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div>...</div>
                      )}
                    </tbody>
                </table>
        </div>
        </div> */}


        </div>
        </div>



                  {/* <div className="container text-center">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group login-form">
                      <button
                        type="button"
                        className="btn btn-success fullwidth"
                        data-toggle="modal"
                        data-target="#myModal2"
                      >
                        New Credit Card
                      </button>
                      </div>
                    </div>
                  </div>
                </div> */}
             
        <div className="spacer-25"></div>
        
        {/* <div className="container text-center">
          <div className="row">
            <div className="col-md-12">
              <h6>BANKS TO RECEIVE PAYMENT</h6>
            </div>
          </div>
        </div> */}

{/* 
        <div className="container">
          <div className="row background ">
            <div className="col-md-12">
                <div className="form-group login-form">
                  <table class="table">
                    <thead class="thead-dark">
                      <tr>
                        
                        <th scope="col">BANK NAME</th>
                        <th scope="col">IBAN</th>
                        <th scope="col">OPERATION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bankList ? (
                        bankList.map((bankListValue, index) => (
                          <tr>
                      
                            <td>{bankListValue.name}</td>
                            <td>{bankListValue.iban}</td>
                            <td style={{'textAlign': 'center'}}> 
                              <a href="#"><i className="fa fa-pencil" aria-hidden="true"></i></a>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <a href="#" data-toggle="modal" data-target="#DeleteBankAccount" onClick={() => setBankAccountIndexNumber(bankListValue.id)}> <i className="fa fa-trash" aria-hidden="true"></i> </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div>...</div>
                      )}
                    </tbody>
                  </table>
        </div>
        </div>
        </div>
        </div> */}

 
              {/* <div className="container text-center">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group login-form">
                      <button
                        type="button"
                        className="btn btn-success fullwidth"
                        data-toggle="modal"
                        data-target="#myModal1"
                      >
                        New Bank Account
                      </button>
                      </div>
                    </div>
                  </div>
                </div> */}
 
                <div className="spacer-25"></div>
                        
                <div className="container text-center">
                  <div className="row">

                    <div className="col">

                      <form className="user" onSubmit={loadMoney}>
                      <div className="form-group login-form">
                      <button type="submit" className="btn btn-primary fullwidth">Load Money</button>
                      </div>
                      </form>
                      </div>

                      <div className="col">
                      <form className="user" onSubmit={withDraw}>
                      <div className="form-group login-form">
                      <button type="submit" className="btn btn-primary fullwidth">Withdraw Funds</button>
                      </div>
                      </form>
                      </div>
 
                </div>
              </div>

        <div className="spacer-25"></div>
      </LoadingOverlay>
      <Footer />
 
     
      <div id="DeleteCreditCard" className={`modal fade ${gosterGostermeCard}`} role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Delete Credit Card </h4>
            </div>
            <div className="modal-body">
              <form className="user" onSubmit={handleSubmitDeleteCreditCard}>

                <div className="form-group login-form">
                  <label for="exampleInputEmail1 form-text-style">
                    <span className="form-text-style">Bank Name {creditCardIndexNumber} </span>
                  </label> 
                </div>

                <div className="form-group login-form">
                  <label for="exampleInputPassword1">
                    <span className="form-text-style">Deleting the Credit Card</span>
                  </label>
                   
                  <label for="exampleInputPassword1">
                    <span className="form-text-style">Are you sure ?</span>
                  </label>
                </div>

                <div className="form-group login-form">
                  
                  <button
                    type="submit"
                    className="btn btn-danger"
                  >
                    Delete
                  </button>

                  <button
                    data-dismiss="modal"
                    className="btn btn-info float-right"
                  >
                    Cancel
                  </button>

                </div>
              </form>
            </div>
            <div className="modal-footer">
            </div>
          </div>
        </div>
      </div>

      <div id="DeleteBankAccount" className={`modal fade ${gosterGostermeBank}`} role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Delete Bank Account</h4>
            </div>
            <div className="modal-body">
              <form className="user" onSubmit={handleSubmitDeleteBankAccount}>

                <div className="form-group login-form">
                  <label for="exampleInputEmail1 form-text-style">
                    <span className="form-text-style">Bank Account  {bankAccountIndexNumber}</span>
                  </label> 
                </div>

                <div className="form-group login-form">
                  <label for="exampleInputPassword1">
                    <span className="form-text-style">Deleting Bank Account</span>
                  </label>
                   
                  <label for="exampleInputPassword1">
                    <span className="form-text-style">Are you sure ?</span>
                  </label>
                </div>

                <div className="form-group login-form">
                <button
                    type="submit"
                    className="btn btn-danger"
                  >
                    Delete
                  </button>
                  <button
                    data-dismiss="modal"
                    className="btn btn-info float-right"
                  >
                    Cancel
                  </button>

                </div>
              </form>
            </div>
            <div className="modal-footer">
            </div>
          </div>
        </div>
      </div>




      <div id="myModal1" className={`modal fade ${gosterGostermeMyModal1}`} role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">New Bank Account</h4>
            </div>
            <div className="modal-body">
              <form className="user" onSubmit={handleSubmit1}>
                <div className="form-group login-form">
                  <label for="exampleInputEmail1 form-text-style">
                    <span className="form-text-style">Bank Name</span>
                  </label>
                  <input
                    type="text"
                    value={newBankName}
                    required
                    onChange={e => {
                      setNewBankName(e.target.value);
                    }}
                    className="form-control form-control-user"
                    placeholder="Bank Name"
                  />
                </div>
                <div className="form-group login-form">
                  <label for="exampleInputPassword1">
                    <span className="form-text-style">Iban</span>
                  </label>
                  <input
                    type="text"
                    value={newIban}
                    required
                    onChange={e => {
                      setNewIban(e.target.value);
                    }}
                    className="form-control form-control-user"
                    placeholder="Iban"
                  />
                </div>
                <div className="form-group login-form">
                  <button
                    type="submit"
                    className="btn btn-primary fullwidth"
                  >
                    Save Bank
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer">
            </div>
          </div>
        </div>
      </div>

      <div id="myModal2" className={`modal fade ${gosterGostermeMyModal2}`} role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">New Credit Card</h4>
            </div>
            <div className="modal-body">
              <form className="user" onSubmit={handleSubmit2}>
                <div className="form-group login-form">
                  <label for="exampleInputEmail1 form-text-style">
                    <span className="form-text-style">Card Holder</span>
                  </label>
                  <input
                    type="text"
                    value={newCardHolderName}
                    required
                    onChange={e => {
                      setNewCardHolderName(e.target.value);
                    }}
                    className="form-control form-control-user"
                    placeholder="Card Holder Name"
                  />
                </div>

                <div className="form-group login-form">
                  <label for="exampleInputPassword1">
                    <span className="form-text-style">Card Number</span>
                  </label>
                  <input
                    type="text"
                    value={newCardNumber}
                    required
                    onChange={e => {
                      setNewCardNumber(e.target.value);
                    }}
                    className="form-control form-control-user"
                    placeholder="Card Number"
                  />
                </div>

                <div className="form-group login-form">
                  <label for="exampleInputPassword1">
                    <span className="form-text-style">CVC Number</span>
                  </label>
                  <input
                    type="text"
                    value={newCvcNumber}
                    required
                    onChange={e => {
                      setNewCvcNumber(e.target.value);
                    }}
                    className="form-control form-control-user"
                    placeholder="CVC Number"
                  />
                </div>

                <div className="form-group login-form">
                  <label for="exampleInputPassword1">
                    <span className="form-text-style">Expire Date</span>
                  </label>
                  <input
                    type="text"
                    value={newExpireDate}
                    required
                    onChange={e => {
                      setNewExpireDate(e.target.value);
                    }}
                    className="form-control form-control-user"
                    placeholder="Expire Date"
                  />
                </div>

                <div className="form-group login-form">
                  <button
                    type="submit"
                    className="btn btn-primary fullwidth"
                  >
                    Save Credit Card
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer">
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankAccounts;
