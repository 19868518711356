import React,{useEffect, useState} from "react";
import { Link, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';
import axios from 'axios';

import {useParams} from "react-router";

import Header from "./includes/header";
import Footer from "./includes/footer";
 
const Activation = () => {

    localStorage.setItem('whereAmI', "Main");
    const [token, setToken] = useState();
 
    const [load, setLoad] = useState(true);
    const [password, setPassword] = useState('');
    const [result, setResult] = useState(0);
    const [redirect, setRedirect] = useState(0);
    
    let { id } = useParams();
 
    useEffect(() => {

        setLoad(false);


        axios.post('https://mobiapp.mahalome.com/user/activation',{
            password: password,
            token: id
        })
        .then(res => {    
            setResult(1);
        })
        .catch(err => {
            
            console.log(err.message);
            setResult(
                <div class="alert alert-danger" role="alert">
                    Temporary error occured, please try again later.
                </div>
            );
            
        })
        


	}, []);

    
if (result===0) {

    return (
        
        <div>
            <Helmet>
            <meta charSet="utf-8" />
            <title>Activation | Mahalome</title>
            <meta name="description" content="Activate your Account" />
            </Helmet>
            <LoadingOverlay active={load} spinner> 
            <Header />
            <div class="spacer-25"></div>
            <div className="col-md-12">
                <h1 className="mt-5">Account Activation</h1>
            </div>
                <div className="main-form">
                <div className="row background transbox">
                    <div className="col-md-12">
                    <form className="user"> 
                        <div className="form-group login-form"> 
                        <label for="exampleInputEmail1 form-text-style"><span className="form-text-style">Your Account has been Activated.</span></label> 
                        </div> 
                    </form>
                    <div className="spacer-25"></div>
                    <div className="spacer-25"></div>
                    </div>
                </div>
            </div>
            <div class="spacer-25"></div>
            </LoadingOverlay>
            <Footer />    
        </div>
 
    );
} else {
return (
        
    <div>
    <Helmet>
    <meta charSet="utf-8" />
    <title>Activation | Mahalome</title>
    <meta name="description" content="Activate your Account" />
    </Helmet>
    <LoadingOverlay active={load} spinner> 
    <Header />
    <div class="spacer-25"></div>
    <div className="col-md-12">
        <h1 className="mt-5">Account Activation</h1>
    </div>
        <div className="main-form">
        <div className="row background transbox">
            <div className="col-md-12">
            <form className="user"> 
                <div className="form-group login-form text-center"> 
                <label for="exampleInputEmail1 form-text-style"><span className="form-text-style">Your Account has been Activated.</span></label> 
                </div> 
            </form>
            <div className="spacer-25"></div>
            <div className="spacer-25"></div>
            </div>
        </div>
    </div>
    <div class="spacer-25"></div>
    </LoadingOverlay>
    <Footer />    
    </div>
 
    );
} 
    
};

export default Activation;
