import React,{useEffect, useState} from "react";
import { Link, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';
import axios from 'axios';

import Header from "./includes/header";
import Footer from "./includes/footer";
 
const ChangePassword = () => {

    localStorage.setItem('whereAmI', "Main");

    const [jwt, setJwt] = useState(localStorage.getItem('jwt'));

    const [load, setLoad] = useState(true);
    const [result, setResult] = useState('');

    const [authCheck, setAuthCheck] = useState('Authenticated');

    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [password3, setPassword3] = useState('');


    const [isActivated, setIsActivated] = useState('');


    useEffect(() => {

        setLoad(false);

        checkActivation();

        axios.get(
            'https://mobiapp.mahalome.com/user/auth/check-session', {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/x-www-form-urlencoded',
                'token': jwt,
                'pageName': 'ChangePassword'
            }
            })
            .then(res => { 
            setAuthCheck(res.data.result);
            })
            .catch(err => {
              setAuthCheck('401');
            }) 

        console.log(jwt);
 
	}, []);
 
    const checkActivation = () => {
        axios.get(
            'https://mobiapp.mahalome.com/user/checkActivation', {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/x-www-form-urlencoded',
                'token': jwt
            }
            })
            .then(res => { 
             // console.log("***********!!!!"+ JSON.stringify(res.data.data.UserStatus[0].status) );
    
                if (res.data.data.UserStatus[0].status === "PASSIVE") {
                    setIsActivated(
                        <div class="alert alert-danger" role="alert">
                        Please activate your account.
                        </div>
                    );
                }
    
            })
            .catch(err => {
              setIsActivated('');
            }) 
    }

    const handleSubmit = event => {

        event.preventDefault();
 
        if (password1!==password2) {
            setResult(
                <div className="alert alert-danger" role="alert">
                    Passwords do not match!
                </div>
            );
        } else {
            axios({
                method: 'put',
                url: "https://mobiapp.mahalome.com/user/changePassword",
                params: {
                  password1: password1,
                  password2: password2,
                  password3: password3
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'token': jwt
                    }
              }).then(res => { 
                
                console.log(res.data.resultDetail);

                setResult(
                    <div className="alert alert-danger" role="alert">
                        {res.data.resultDetail}
                    </div>
                );
            })
            .catch(err => { 
                setResult(
                    <div className="alert alert-danger" role="alert">
                        Temporary Error Occured
                    </div>
                );
            })
        }


 
 
    }

	// Redirect if there is token
	if(authCheck!=="Authenticated" ){
        return <Redirect to="/login" />
    }

    return (
        
        <div>
            <Helmet>
            <meta charSet="utf-8" />
            <title>Change Password | Mahalome</title>
            <meta name="description" content="Send Money" />
            </Helmet>
            <LoadingOverlay active={load} spinner> 

            <Header />
            <div className="spacer-25"></div>

            <div className="col-md-12">
                <h1 className="mt-5">Change Password</h1>
            </div>

            {isActivated}
 
            <div className="main-form">
                <div className="row background transbox">
                    <div className="col-md-12">
                    <form className="user" onSubmit={handleSubmit}>

                        <div className="form-group login-form">
                        <label className="exampleInputEmail1 form-text-style"><span className="form-text-style">Password</span></label>
                        <input type="password" value={password1} required onChange={(e) => { setPassword1(e.target.value) }} className="form-control form-control-user" />
                        </div>
                        
                        <div className="form-group login-form">
                        <label className="exampleInputEmail1 form-text-style"><span className="form-text-style">Retype Password</span></label>
                        <input type="password" value={password2} required onChange={(e) => { setPassword2(e.target.value) }} className="form-control form-control-user" />
                        </div>

                        <div className="form-group login-form"><hr /></div>

                        <div className="form-group login-form">
                        <label className="exampleInputEmail1 form-text-style"><span className="form-text-style">Current Password</span></label>
                        <input type="password" value={password3} required onChange={(e) => { setPassword3(e.target.value) }} className="form-control form-control-user" />
                        </div>

                        <div className="form-group login-form">
                        <button type="submit" className="btn btn-primary fullwidth">Update Password</button>
                        </div>

                    </form>

                    <div className="col-md-12">{result}</div>
                    
                    <div className="spacer-25"></div> 
                    <div className="spacer-25"></div>
                    </div>
        
                    </div>
            </div>
 
            <div className="spacer-25"></div>

            </LoadingOverlay>
            <Footer />    
        </div>
 
    );
};

export default ChangePassword;
