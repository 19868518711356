import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const [activeClass, setActiveClass] = useState("");

  const [mainMenu, setMainMenu] = useState();

  useEffect(() => {}, []);
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col footer-item">
            <a href="/about">
              <i class="fa fa-info-circle mr-2" aria-hidden="true"></i> About
            </a>
          </div>
          {/* <div className="col footer-item">
            <a href="/legal">
              <i class="fa fa-gavel mr-2" aria-hidden="true"></i> Legal
            </a>
          </div> */}
          <div className="col footer-item">
            <a href="/privacy">
              <i class="fa fa-user-secret mr-2" aria-hidden="true"></i> Privacy
            </a>
          </div>
          <div className="col footer-item">
            <a href="/help">
              <i class="fa fa-question-circle mr-2" aria-hidden="true"></i> Help
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
