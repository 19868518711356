import React,{useEffect, useState} from "react";
import { Link, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';
import axios from 'axios';

import Header from "./includes/header";
import Footer from "./includes/footer";
 
const WithdrawFunds = () => {

    localStorage.setItem('whereAmI', "Main");

    const [jwt, setJwt] = useState(localStorage.getItem('jwt'));

    const [load, setLoad] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [result, setResult] = useState('');
    const [redirect, setRedirect] = useState(0);
 
    const [balance, setBalance] = useState(0);
    const [bonus, setBonus] = useState(0);
    const [tips, setTips] = useState(0);
    const [transfer, setTransfer] = useState(0);
    const [isActivated, setIsActivated] = useState('');

    const [buttonMoneyValue, setButtonMoneyValue] = useState('');
    const [maxTransferToBank, setMaxTransferToBank] = useState(0);
    const [bankList, setBankList] = useState([]);
    const [bankId, setBankId] = useState([]);
    
    const [walletMoney, setWalletMoney] = useState(0);
    const [walletBonus, setWalletBonus] = useState(0);
    const [mustSpent, setMustSpent] = useState(0);
    const [minWallet, setMinWallet] = useState(0);

    const [withdrawButton, setWithdrawButton] = useState('');
    const [withdrawMessage, setWithdrawMessage] = useState('');
    
    const [authCheck, setAuthCheck] = useState('Authenticated');

    useEffect(() => {

        setLoad(false);
         
        checkActivation();

        axios.get(
            'https://mobiapp.mahalome.com/user/auth/check-session', {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/x-www-form-urlencoded',
                'token': jwt
            }
            })
            .then(res => { 
            setAuthCheck(res.data.result);
            })
            .catch(err => {
              setAuthCheck('401');
            }) 

        getList();
        getBalance();
	}, []);
    


    const checkActivation = () => {
        axios.get(
            'https://mobiapp.mahalome.com/user/checkActivation', {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/x-www-form-urlencoded',
                'token': jwt
            }
            })
            .then(res => { 
             // console.log("***********!!!!"+ JSON.stringify(res.data.data.UserStatus[0].status) );
    
                if (res.data.data.UserStatus[0].status === "PASSIVE") {
                    setIsActivated(
                        <div class="alert alert-danger" role="alert">
                        Please activate your account.
                        </div>
                    );
                }
    
            })
            .catch(err => {
                setIsActivated('');
            }) 
    }
 
const getBalance = event => {


    axios.get(
        'https://mobiapp.mahalome.com/user/wallet-all', {
        headers: {
            'accept': 'application/json',
            'content-type': 'application/x-www-form-urlencoded',
            'token': jwt
            // 'content-type': 'application/x-www-form-urlencoded'
        }
    })
    .then(res => {
    console.log(res.data.data.walletHistory[0]);
        setWalletMoney(res.data.data.walletHistory[0].wallet_money);
        setWalletBonus(res.data.data.walletHistory[0].wallet_bonus);
        setMustSpent(res.data.data.walletHistory[0].must_spent);
        setMinWallet(res.data.data.walletHistory[0].min_wallet);
         
        let caldulatedAvailableAmount = 0;
 
        var myWalletMoney = res.data.data.walletHistory[0].wallet_money;
        var myWalletMoney = myWalletMoney.replace(",", "");

        if (res.data.data.walletHistory[0].must_spent < res.data.data.walletHistory[0].min_wallet) {

                caldulatedAvailableAmount = (
                Number(myWalletMoney) 
                + 
                Number(res.data.data.walletHistory[0].wallet_bonus) 
                - 
                Number(res.data.data.walletHistory[0].min_wallet)
            );    

            caldulatedAvailableAmount = Number(caldulatedAvailableAmount);

        } else {

                caldulatedAvailableAmount = (Number(res.data.data.walletHistory[0].wallet_money) 
                + 
                Number(res.data.data.walletHistory[0].wallet_bonus) 
                - 
                Number(res.data.data.walletHistory[0].must_spent)
            );   
            
            caldulatedAvailableAmount = Number(caldulatedAvailableAmount);

        }
 



                    axios({
                        method: "post",
                        url: "https://mobiapp.mahalome.com/user/service/get-stripe-user-id",
                        params: {
                        },
                        headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        token: jwt
                        }
                    })
                        .then(res => {
                            // console.log("*********"+  res.data.data.stripeUserId );
                        if (res.data.data.stripeUserId) {


                            // ...........
                            if (caldulatedAvailableAmount<20 ) {
                                caldulatedAvailableAmount = 0;
                                setWithdrawMessage(<div>20$ is the minimum limit that has to be in your wallet to withdraw</div>)
                                setWithdrawButton(<button type='submit' disabled className='btn btn-primary fullwidth'>Withdraw</button>);
                            }  else {
                                setWithdrawButton(<button type='submit' className='btn btn-primary fullwidth'>Withdraw</button>);
                            }


                        } else {
                            
                            // ...........
                            setWithdrawButton(<a href="/bank-accounts" className='btn btn-primary fullwidth'>Please go to Bank Accounts</a>);

                        }
                        })
                        .catch(err => {
                        setResult(
                            <div className="alert alert-danger" role="alert">
                            Temporary Error Occured
                            </div>
                        );
                        });
 
        setMaxTransferToBank(caldulatedAvailableAmount);

    })
    .catch(err => {

        setResult(
            <div className="alert alert-danger" role="alert">
              Temporary Error Occured
            </div>
          );
        
    })

}

    const handleSubmit = event => {

        event.preventDefault();

        axios({
            method: "post",
            // url: "https://mobiapp.mahalome.com/user/wallet/transfer/user-to-bank",
            url: "https://mobiapp.mahalome.com/user/wallet/transfer/check-user-to-bank",
            params: {
                //bankId:bankId,
                amount:buttonMoneyValue
            },
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              token: jwt
            }
          })
            .then(res => {
                
            console.log("!!!!***********!!!!"+ res.data.resultDetail );

              setResult(  
                <div className="alert alert-danger" role="alert">
                {res.data.resultDetail}
              </div>);

            })
            .catch(err => {
              setResult(
                <div className="alert alert-danger" role="alert">
                  Temporary Error Occured
                </div>
              );
            });

        
    }


    const getList = event => {
        // Banks
        axios
        .get("https://mobiapp.mahalome.com/user/bank", {
          headers: {
            accept: "application/json",
            "content-type": "application/x-www-form-urlencoded",
            token: jwt
          }
        })
        .then(res => {
          setBankList(res.data.data.Banks);
          setRedirect(1);
        })
        .catch(err => {
          setResult(
            <div className="alert alert-danger" role="alert">
              Temporary Error Occured
            </div>
          );
        });
    }

	// Redirect if there is token
	if(authCheck!=="Authenticated" ){
    return <Redirect to="/login" />
}

    return (
        
        <div>
            <Helmet>
            <meta charSet="utf-8" />
            <title>Withdraw Funds | Mahalome</title>
            <meta name="description" content="Withdraw Funds" />
            </Helmet>
            <LoadingOverlay active={load} spinner> 

            <Header />
            <div className="spacer-25"></div>

            <div className="col-md-12">
                <h1 className="mt-5"><a href="/bank-accounts"><i class="fa fa-arrow-left" aria-hidden="true"></i></a> Withdraw Funds</h1>
            </div>
 
            {isActivated}
            
                <div className="main-form">
                <div className="row background transbox">
                    {/* left spacer */}
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                    <form className="user" onSubmit={handleSubmit}>

                    <div className="form-group login-form text-center form-text-style">
                       Wallet Money!
                    </div>
                     
                    <div className="form-group login-form text-center">
                     <h2><i class="fa fa-usd" aria-hidden="true"></i> {walletMoney}</h2>
                    </div>

                    <div className="form-group login-form text-center form-text-style">
                       Wallet Bonus
                    </div>
                     
                    <div className="form-group login-form text-center">
                       <h2><i class="fa fa-usd" aria-hidden="true"></i> {walletBonus}</h2>
                    </div>

                    <div className="form-group login-form text-center form-text-style">
                       Must Spent
                    </div>
                     
                    <div className="form-group login-form text-center">
                       <h2><i class="fa fa-usd" aria-hidden="true"></i> {mustSpent}</h2>
                    </div>

                    <div className="form-group login-form text-center form-text-style">
                       Max. transfer to Bank!
                    </div>
                     
                    <div className="form-group login-form text-center">
                       <h2><i class="fa fa-usd" aria-hidden="true"></i> {maxTransferToBank}</h2>
                       <small className="form-text text-muted"><i className="fas fa-sign-in-alt"></i> {withdrawMessage}</small>


                    </div>
                     
                    <div className="spacer-25"></div>
                        
                        {/* <div className="form-group login-form text-center">
                        <label for="exampleInputEmail1 form-text-style"><span className="form-text-style">DEFINED BANKS</span></label>
                        
                        <select className="form-control form-control-user" value={bankId} onChange={(e) => { setBankId(e.target.value) }}>
                            <option value='xxx'>-- Please select from saved receivers --</option> 
                            {bankList ? (
                            bankList.map((bankListValue, index) => (
                                <option value={bankListValue.id}>{bankListValue.name} </option>
                                ))
                                ) : (
                                 ''
                                )}
                        </select> 

                        <small className="form-text text-muted"><i className="fas fa-sign-in-alt"></i>Please select from pre-defined amounts.</small>
                        </div> */}

                    <div className="form-group login-form text-center">
                        <label for="exampleInputEmail1 form-text-style"><span className="form-text-style">WITHDRAW AMOUNT</span></label>
                        <input type="number" value={buttonMoneyValue} onChange={(e) => { setButtonMoneyValue(e.target.value) }} className="form-control form-control-user" />
                        <i className="fas fa-sign-in-alt"></i><span className="form-text-style">Please type if you prefer to send another amount.</span>
                    </div>
                    
                    <div className="form-group login-form">
                        {withdrawButton}
                    </div>
                    <div className="form-group login-form">
                        <a href="/bank-accounts" className="btn btn-primary fullwidth">Back to Bank Accounts</a>
                    </div>

                    <div className="form-group login-form">
                    {result}
                    </div>

                    </form>
                    <div className="spacer-25"></div>
                    <div className="spacer-25"></div>
                    </div>
                    {/* center-8 ... */}
 
                    {/* right spacer */}
                    <div className="col-md-2"></div>

                </div>
            </div>
 
            <div class="spacer-25"></div>

            </LoadingOverlay>
            <Footer />    
        </div>
 
    );
};

export default WithdrawFunds;
